import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

//modal
import { Modal } from "react-bootstrap";

//svg import
import { ReactComponent as Next } from "../../resources/svg/next.svg";
import { ReactComponent as Job } from "../../resources/svg/40-bag.svg";

//scss import
import CreateJobStyle from "./createJob.module.scss";

//connector import
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

//comoponent import
import ConfirmBundle from "../confirmBundleInfo/confirmBundle";

//bootstrap import
import { Col, Row, Form } from "react-bootstrap";
import Preset from "../preset/preset";
import JobCreated from "../jobCreate/jobCreate";
import Autocomplete from "../autoComplete/autoComplete";
import AddBidder from "../../pages/addBidder/addBidder";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

//stepper
const steps = ["Product Info", "Process", "Bundle Info"];

//connector start
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    fontFamily: "Poppins-Medium",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0040A8",
      fontFamily: "Poppins-Medium",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#0040A8",
      fontFamily: "Poppins-Medium",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 25,
  fontFamily: "Poppins-Medium",
  height: 25,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#0040A8",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    fontFamily: "Poppins-Medium",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#0040A8",
    fontFamily: "Poppins-Medium",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <Check />,
    2: <Check />,
    3: <Check />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{
        "& .MuiSvgIcon-root": {
          width: "0.7em",
        },
      }}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

//connector end
export default function Steppers({ show, setShow }) {
  const dispatch = useDispatch();
  const [jobName, setJobName] = useState();
  const [activeStep, setActiveStep] = React.useState(0);
  const [stepList, setStepList] = React.useState([]);
  const [completed, setCompleted] = React.useState({});
  const [suggestions, setSuggestions] = React.useState({
    jobName: [],
  });
  const [stepJobs, setStepJobs] = useState({
    nameofJob: "",
  });

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const postJob = async () => {
    // let res = { payload: { statusCode: true } };
    // if (res?.payload?.statusCode) {
    window.location.href = "/jobs";
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  // const handleStep = (step) => () => {
  //   setActiveStep(step);
  // };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <>
      <Modal
        style={{
          overflow: "hidden",
        }}
        contentClassName={CreateJobStyle.modalContent}
        dialogClassName={CreateJobStyle.bdaModal}
        aria-labelledby="createjob"
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Body className={CreateJobStyle.bodyModal}>
          <Box
            sx={{
              maxHeight: "90vh",
              background: "#f5f5f5",
              py: 2,
              px: 3,
              width: "100%",
              borderRadius: "15px",
            }}
          >
            <Form>
              <Row>
                <Col xl={2}>
                  <Stepper
                    sx={{
                      pl: 3,
                      py: 25,
                      "& .MuiStepLabel-label": {
                        fontSize: 16,
                        color: "#0040A8 !important",
                        fontFamily: "Poppins-Medium",
                      },
                      "& .MuiStepLabel-labelContainer": {
                        color: "#0040A8 !important",
                        fontFamily: "Poppins-Medium",
                      },
                      "& .MuiStepLabel-root": {
                        p: 0,
                        color: "#0040A8 !important",
                        fontFamily: "Poppins-Medium",
                      },
                      "& .MuiStepIcon-root": {
                        color: "#0040A8 !important",
                      },
                      "& .MuiStepConnector-root": {
                        ml: "10px !important",
                      },
                      "& .MuiStepConnector-lineVertical": {
                        width: 5,
                        height: "125px !important",
                      },
                    }}
                    orientation="vertical"
                    nonLinear
                    activeStep={activeStep}
                    connector={<ColorlibConnector />}
                  >
                    {steps.map((label, index) => (
                      <Step key={label} completed={completed[index]}>
                        <StepButton
                          sx={{ fontFamily: "Poppins-Medium" }}
                          color="red"
                          // onClick={handleNext(index)}
                        >
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Col>
                <Col>
                  <Row style={{ paddingBottom: "10px" }}>
                    <Form.Group
                      className={CreateJobStyle.formGroup}
                      controlId="nameofJob"
                    >
                      <Row>
                        <Col xl={2}>
                          <Form.Label className={CreateJobStyle.formLabel}>
                            Name/Style
                          </Form.Label>
                        </Col>
                        <Col xl={10}>
                          <Autocomplete
                            type="text"
                            placeholder="Name/Style"
                            name="nameofJob"
                            className={CreateJobStyle.formControl}
                            value={""}
                            setValue={() => {}}
                            suggestions={suggestions.jobName}
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Row>
                  <Row>
                    <>
                      {allStepsCompleted() ? (
                        <>
                          <Typography
                            sx={{
                              fontFamily: "Poppins-SemiBold",
                              fontSize: 20,
                              mt: 2,
                              mb: 1,
                              textAlign: "center",
                            }}
                          >
                            Your Job Is Being Created. Please Print QR Codes in
                            Jobs Sections after Reload
                            {/* <JobCreated /> */}
                            <CircularProgress
                              sx={{
                                width: "7rem !important",
                                height: "7rem !important",
                                position: "absolute",
                                left: "50%",
                                top: "50%",
                              }}
                            />
                          </Typography>
                          {/* <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              pt: 2,
                            }}
                          >
                            <Box sx={{ flex: "1 1 auto" }} />
                            <Button onClick={handleReset}>Reset</Button>
                          </Box> */}
                        </>
                      ) : (
                        <>
                          {activeStep === 0 && (
                            <div className={CreateJobStyle.height}>
                              add edit idhr tha
                            </div>
                          )}
                          {activeStep === 1 && (
                            <div className={CreateJobStyle.height}>
                              <AddBidder createJob={true} />
                            </div>
                          )}
                          {activeStep === 2 && (
                            <div className={CreateJobStyle.height}>
                              <ConfirmBundle />
                            </div>
                          )}
                          <Row
                            className={CreateJobStyle.btnRow}
                            // style={{ marginTop: "107px" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                pt: !(activeStep === totalSteps() - 1)
                                  ? 2.5
                                  : 1,
                                width: "40%",
                                m: "auto",
                              }}
                            >
                              <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 7 }}
                              >
                                Back
                              </Button>
                              {/* <Box sx={{ flex: "1 1 auto" }} /> */}
                              {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                              </Button> */}
                              {activeStep !== steps.length && (
                                <Button
                                  sx={{
                                    backgroundColor: "#0040A8",
                                    color: "#fff",
                                    fontFamily: "Poppins-Medium !important",
                                    textTransform: "none",
                                    px: 3,
                                    borderRadius: 2,
                                    fontSize: 16,
                                    "&:hover": {
                                      backgroundColor: "#0040A8",
                                    },
                                  }}
                                  onClick={() => {}}
                                >
                                  {activeStep === totalSteps() - 1 && (
                                    <>
                                      <Job className={CreateJobStyle.job} />
                                      Create Job
                                    </>
                                  )}
                                  {activeStep !== totalSteps() - 1 && (
                                    <>
                                      Next{" "}
                                      <Next className={CreateJobStyle.next} />
                                    </>
                                  )}
                                </Button>
                              )}
                            </Box>
                          </Row>
                        </>
                      )}
                    </>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
}
