import React from "react";
import { DataGrid } from "@mui/x-data-grid";

//scss import
import Styles from "./franchise.module.scss";

// bootstrap import
import { Container, Row, Col } from "react-bootstrap";

// //svg import
import { ReactComponent as Add } from "../../resources/svg/Add.svg";
import { ReactComponent as Edit } from "../../resources/svg/Group 79.svg";
import { ReactComponent as Delete } from "../../resources/svg/Trash.svg";
import { ReactComponent as Deleteb } from "../../resources/svg/buetrash.svg";

// components import
import Cbtn from "../../components/commonbutton/cbtn";
import ResponsiveDrawer from "../../components/responsiveDrawer/responsiveDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import Deletefilemodal from "../../components/deletfilemodal/deletefilemodal";
import { useDispatch, useSelector } from "react-redux";
import { selectStatus } from "../../store/features/adminSlice";
import {
  getFranchise,
  getFranchiseById,
  selectfranchiseData,
  deleteFranchise,
  selectFranchiseError,
} from "../../store/features/franchiseSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
// import AddFranchise from "../../components/addFranchise/addFranchise";
// import EditFranchise from "../../components/editFranchise/editFranchise";

const Franchise = () => {
  const [franchiseRow, setFranchiseRow] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showSeq, setShowSeq] = React.useState(false);
  const [showEditSeq, setShowEditSeq] = React.useState([]);
  const [showDel, setShowDel] = React.useState(false);
  const [deleteIds, setDeleteIds] = React.useState([]);

  let navigate = useNavigate();
  let location = useLocation();
  let loadingStatus = useSelector(selectStatus);

  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  React.useEffect(() => {
    if (loadingStatus === "error") {
      navigate("/login");
    }
  }, [loadingStatus]);

  let dispatch = useDispatch();
  let teamDetails = useSelector(selectfranchiseData);
  let teamError = useSelector(selectFranchiseError);

  let getData = async () => {
    let res = await dispatch(getFranchise());
    setLoading(false);
    return res;
  };

  React.useEffect(() => {
    if (teamDetails[0]) {
      setFranchiseRow(() => {
        let temp = teamDetails.map((ele, i) => ({
          id: ele?._id,
          count: i + 1,
          name: ele?.name,
          ownerName: ele?.owner?.name,
          purse: ele?.owner?.purse,
          biddingPlayersBought: ele?.boughtPlayers?.length || 0,
          editDelete: {
            details: ele,
            // show: false,
            // setShow: (value) => {
            //   setFranchiseRow((prev) => {
            //     let temp = [...prev];
            //     temp[i].editDelete.show = value;
            //     return temp;
            //   });
            // },
          },
        }));
        return temp;
      });
    }
  }, [teamDetails]);

  React.useEffect(() => {
    getData();
  }, []);

  const columns = [
    // Product Name----FIRST TITLE CELL---
    {
      field: "count",
      headerName: "SR",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      // minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <div>{params.value}</div>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Teams",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 3,
      // minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <div>{params.value}</div>
          </>
        );
      },
    },
    {
      field: "ownerName",
      headerName: "Owner",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 2,
      // minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <div>{params.value ? params.value : "-"}</div>
          </>
        );
      },
    },
    {
      field: "purse",
      headerName: "Purse",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 2,
      // minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <div>{params.value ? `${params.value / 100} CR` : "-"} </div>
          </>
        );
      },
    },
    {
      field: "biddingPlayersBought",
      headerName: "Players Bought",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 2,
      // minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <div>{params.value}</div>
          </>
        );
      },
    },
    // NO HEADER NAME ----- FIFTH TITLE CELL----

    {
      field: "editDelete",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 2,
      minWidth: 200,
      renderHeader: () => {
        return (
          <>
            <Cbtn
              className="huehuehue-white"
              type={"submit"}
              onClick={() => {
                if (deleteIds[0]) {
                  setShowDel(!showDel);
                } else {
                  alert("Select Franchises");
                }
              }}
            >
              <Deleteb
                style={{
                  marginRight: "8px",
                  marginBottom: "5px",
                }}
              />
              Delete
            </Cbtn>
          </>
        );
      },
      renderCell: (params) => {
        return (
          <>
            <Edit
              onClick={() => {
                navigate(`/teams/${params.value.details._id}`);
              }}
              className={Styles.editSvg}
            />
            {/* <EditFranchise
              teamDetails={params.value.details}
              show={params.value.show}
              setShow={params.value.setShow}
            /> */}
            <Delete
              onClick={() => {
                setDeleteIds([params.value.details._id]);
                setShowDel((prev) => !prev);
              }}
              className={Styles.deleteSvg}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Container>
            <Row className={Styles.rightContainer}>
              <Col md={{ span: 4 }}>
                <h1 className={Styles.mainHeading}>Franchise</h1>
              </Col>
              <Col md={{ span: 3, offset: 5 }}>
                <Cbtn
                  className="huehuehue"
                  type={"submit"}
                  to="/teams/new"
                  style={{
                    float: "right",
                    marginRight: "1rem",
                    // width: "100%",
                    backgroundColor: "#0040A8",
                    marginTop: "2rem",
                  }}
                >
                  <Add className={Styles.add} />
                  New Team
                </Cbtn>
                {/* <AddFranchise show={showSeq} setShow={setShowSeq} /> */}
              </Col>
            </Row>

            {/* ------------------TABLE START----------- */}

            <Row className={`${Styles.table} ${Styles.rightContainer}`}>
              <DataGrid
                autoWidth
                autoHeight
                rowHeight={55}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                headerHeight={80}
                onSelectionModelChange={(ids) => {
                  setDeleteIds(ids);
                }}
                pageSize={20}
                rowsPerPageOptions={[20]}
                sx={{
                  maxHeight: "70vh",
                  borderRadius: "10px",
                  border: "none",
                  width: "calc(inherit - 100px)",
                  padding: 0,
                  boxShadow: "1px 1px 6px 1px #eaeaed",
                  "& .MuiDataGrid-main": {
                    borderRadius: "10px",
                    maxHeight: "70vh",
                    overflowY: "overlay",
                  },
                  "& .MuiCheckbox-root": {
                    color: " #DBDBDB!important",
                  },
                  "& .MuiCheckbox-root.Mui-checked": {
                    color: "#0040A8 !important",
                  },
                  "& 	.MuiDataGrid-row": {
                    backgroundColor: "#fff",
                  },
                  '& div[data-rowIndex][role="row"]': {
                    color: "#363B64",
                    fontSize: 14,
                    fontFamily: "Poppins-Medium",
                    borderBottom: "3px solid rgb(245 245 245)",
                    "& div": {
                      border: "none",
                      fontSize: 14,
                    },
                  },
                  "& 	.MuiDataGrid-cellCheckbox": {
                    width: "100px !important",
                    maxWidth: "100px !important",
                    minWidth: "100px !important",
                    marginRight: "-25px !important",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    overflow: "inherit",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    border: "none",
                    borderBottom: "2px solid rgb(245 245 245)",

                    "& .MuiDataGrid-columnHeadersInner": {
                      backgroundColor: "#FCFCFC",

                      "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Poppins-SemiBold",
                        color: "#363B64",
                        fontSize: "14px",
                      },
                    },
                    "& .MuiDataGrid-cell--textCenter": {
                      marginLeft: "2rem",
                    },
                  },
                  "& .MuiDataGrid-cellContent": {
                    fontFamily: "Poppins-Regular !important",
                    color: "#363B64",
                    fontStyle: "1rem",
                  },

                  "& .MuiDataGrid-footerContainer": {
                    display: "none",
                    fontFamily: "Poppins-Medium",
                    borderRadius: "0px 0px 10px 10px",
                    background: "#fff !important",
                    minHeight: "35px",
                    "& .css-levciy-MuiTablePagination-displayedRows": {
                      margin: "1rem",
                    },
                  },
                  "& .MuiDataGrid-columnHeaderCheckbox, .css-1yoodjx-MuiDataGrid-root .MuiDataGrid-cellCheckbox":
                    {
                      width: "100px !important",
                      maxWidth: "100px !important",
                      minWidth: "100px !important",
                      marginRight: "-25px !important",
                    },
                }}
                loading={loading && !teamError}
                rows={franchiseRow}
                columns={columns}
                checkboxSelection
              />
            </Row>
          </Container>
          <Deletefilemodal
            ids={deleteIds}
            show={showDel}
            deleteFn={deleteFranchise}
            setShow={setShowDel}
          />

          {/* </Row> */}
          {/* -------------------TABLE END------------------ */}
        </ResponsiveDrawer>
      )}
    </>
  );
};

export default Franchise;
