import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import curatorRoutes from "../network routes/curator";

export const getCurator = createAsyncThunk("curator/getCurator", async () => {
  const response = await curatorRoutes.getCurators();
  return response;
});

export const getCuratorById = createAsyncThunk(
  "curator/getCuratorById",
  async (id) => {
    const response = await curatorRoutes.getCuratorsById(id);
    return response;
  }
);

export const createCurator = createAsyncThunk(
  "curator/createCurator",
  async (data) => {
    const response = await curatorRoutes.createCurator(data);
    return response;
  }
);

export const editCurator = createAsyncThunk(
  "curator/editCurator",
  async (data) => {
    const response = await curatorRoutes.editCurator(data);
    return response;
  }
);

export const deleteCurator = createAsyncThunk(
  "curator/deleteCurator",
  async (id) => {
    const response = await curatorRoutes.deleteCurator(id);
    return response;
  }
);

export const assignCurator = createAsyncThunk(
  "curator/assignCurator",
  async (data) => {
    const response = await curatorRoutes.assignCurator(data);
    return response;
  }
);

const curatorSlice = createSlice({
  name: "curator",
  initialState: {
    data: [],
    dataById: {},
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getCurator.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data[0]) {
        state.data = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getCurator.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getCuratorById.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.dataById = action.payload.data.data;
      } else {
        state.error = "Login first";
      }
    },
    [getCuratorById.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createCurator.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.data.push(action.payload.data);
      } else {
        state.error = "Login first";
      }
    },
    [createCurator.rejected]: (state, action) => {
      state.error = action.error;
    },
    [editCurator.fulfilled]: (state, action) => {
      // // console.log(action);
      if (action.payload.statusCode === 200) {
        let index = state.data.findIndex(
          (ele) => ele._id === action.payload.data._id
        );
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [editCurator.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteCurator.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        if (state.data) {
          //   let index = state.data.findIndex(
          //     (ele) => ele._id === action.payload.data._id
          //   );
          //   state.data[index] = action.payload.data;
        } else {
          state.error = "Login first";
        }
      }
    },
    [deleteCurator.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const selectcuratorData = (state) => state.curator.data;
export const selectCuratorError = (state) => state.curator.error;
export const selectCuratorDataById = (state) => state.curator.dataById;
export default curatorSlice.reducer;
