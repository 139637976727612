import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

export default function CheckboxLabels(props) {
  let [checked, setChecked] = useState(false);
  const { value, setValue, width, fontSize, flexDirection, checkboxStyle } =
    props;
  return (
    <FormGroup
      sx={{
        flexDirection: flexDirection || "row",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <FormControlLabel
        sx={{
          ...checkboxStyle,
          width: width ? width : "auto",
          "& .MuiTypography-root": {
            fontFamily: props.checkfontFamily
              ? props.checkfontFamily
              : "Poppins-Regular",
            color: props.checkLabelcolor ? props.checkLabelcolor : "#363b64",
            fontSize: fontSize ? fontSize + " !important" : "auto",
          },
          "& .MuiCheckbox-root.Mui-checked": {
            color: "#0040A8",
          },
          "& .MuiCheckbox-root": { color: "#DBDBDB" },
        }}
        control={
          <Checkbox
            disabled={props.disabled}
            checked={value ? value : checked}
            onClick={
              setValue
                ? setValue
                : () => {
                    setChecked(!checked);
                  }
            }
          />
        }
        label={props.label ? props.label : ""}
      />
      {props.children}
    </FormGroup>
  );
}
