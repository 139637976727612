import React from "react";
import { DataGrid } from "@mui/x-data-grid";

//scss import
import Styles from "./bidder.module.scss";

// bootstrap import
import { Container, Row, Col } from "react-bootstrap";

// //svg import
import { ReactComponent as Add } from "../../resources/svg/Add.svg";
import { ReactComponent as Edit } from "../../resources/svg/Group 79.svg";
import { ReactComponent as Delete } from "../../resources/svg/Trash.svg";
import { ReactComponent as Deleteb } from "../../resources/svg/buetrash.svg";

// components import
import Cbtn from "../../components/commonbutton/cbtn";
import ResponsiveDrawer from "../../components/responsiveDrawer/responsiveDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import Deletefilemodal from "../../components/deletfilemodal/deletefilemodal";
import { useDispatch, useSelector } from "react-redux";
import { selectStatus } from "../../store/features/adminSlice";
import {
  getBidder,
  getBidderById,
  selectbidderData,
  deleteBidder,
  selectBidderError,
  assignFranchise,
} from "../../store/features/teamOwnerSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import AddBidder from "../addBidder/addBidder";
import EditBidder from "../../components/editBidder/editBidder";
import SelectLabels from "../../components/dropdowns/1/dropdown1";
import {
  getFranchise,
  getFreeFranchise,
  selectfranchiseData,
  selectFreeFranchiseData,
} from "../../store/features/franchiseSlice";

const Bidders = () => {
  const [franchiseOptions, setFranchiseOptions] = React.useState([]);
  const [bidderRow, setBidderRow] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showSeq, setShowSeq] = React.useState(false);
  const [showEditSeq, setShowEditSeq] = React.useState([]);
  const [showDel, setShowDel] = React.useState(false);
  const [deleteIds, setDeleteIds] = React.useState([]);

  let navigate = useNavigate();
  let location = useLocation();
  let loadingStatus = useSelector(selectStatus);

  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  React.useEffect(() => {
    if (loadingStatus === "error") {
      navigate("/login");
    }
  }, [loadingStatus]);

  let dispatch = useDispatch();
  let bidDetails = useSelector(selectbidderData);
  let bidError = useSelector(selectBidderError);

  let getData = async () => {
    let res = await dispatch(getBidder());
    setLoading(false);
    return res;
  };

  React.useEffect(() => {
    getData();
  }, []);

  let franchises = useSelector(selectFreeFranchiseData);

  React.useEffect(() => {
    dispatch(getFreeFranchise());
  }, []);

  React.useEffect(() => {
    // console.log(franchises);
    if (franchises[0]) {
      setFranchiseOptions(() =>
        franchises.map((team) => ({ option: team.name }))
      );
    }
  }, [franchises]);

  React.useEffect(() => {
    if (bidDetails) {
      setBidderRow(() => {
        let temp = bidDetails.map((ele, i) => ({
          id: ele?._id,
          _id: ele?._id,
          count: i + 1,
          name: ele?.name,
          franchiseName: { name: ele?.Franchise?.name || "None", id: ele?._id },
          userId: ele?.userId,
          purse: ele?.purse,
          editDelete: {
            details: ele,
            show: false,
            setShow: (value) => {
              setBidderRow((prev) => {
                let temp = [...prev];
                temp[i].editDelete.show = value;
                return temp;
              });
            },
          },
        }));
        return temp;
      });
    }
  }, [bidDetails]);

  const columns = [
    // Product Name----FIRST TITLE CELL---
    {
      field: "count",
      headerName: "SR",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return (
          <>
            <div>{params.value}</div>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Team Owners",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 2,
      // minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <div>{params.value}</div>
          </>
        );
      },
    },
    {
      field: "userId",
      headerName: "ID",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      // minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <div>{params.value}</div>
          </>
        );
      },
    },
    {
      field: "franchiseName",
      headerName: "Franchise",
      flex: 2,
      // minWidth: 115,
      sortable: true,
      renderHeader: (params) => {
        return (
          <div
            style={{
              fontFamily: "Poppins-SemiBold",
              fontSize: "0.8rem",
            }}
          >
            {params.colDef.headerName}
          </div>
        );
      },
      renderCell: (params) => {
        return <div>{params.value.name}</div>;
      },
      // renderCell: (params) => {
      //   if (franchiseOptions[0]) {
      //     return (
      //       <SelectLabels
      //         fontSize="0.8rem"
      //         submit={true}
      //         selectBg="transparent"
      //         slist={franchiseOptions}
      //         active={params.value.name || ""}
      //         setActive={async (e) => {
      //           let Franchise = await franchises?.find(
      //             (team) => team.name === e.target.value
      //           );
      //           if (Franchise) {
      //             await dispatch(
      //               assignFranchise({
      //                 _id: params.value.id,
      //                 franchiseId: Franchise._id,
      //               })
      //             );
      //             await setBidderRow((prev) =>
      //               prev.map((ele, i) =>
      //                 ele.id === params.value.id
      //                   ? {
      //                       ...ele,
      //                       franchiseName: {
      //                         name: e.target.value,
      //                       },
      //                     }
      //                   : { ...ele }
      //               )
      //             );
      //             await setFranchiseOptions((prev) => {
      //               prev.filter((options) => options.option !== e.target.value);
      //               return prev;
      //             });
      //           }
      //         }}
      //       />
      //     );
      //   } else {
      //     return (
      //       <>
      //         <div>{params.value.name}</div>
      //       </>
      //     );
      //   }
      // },
      sortComparator: (v1, v2) => v1.status.localeCompare(v2.status),
    },
    {
      field: "purse",
      headerName: "Purse",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      // minWidth: 300,
      renderCell: (params) => {
        return (
          <>
            <div>{params.value / 100} CR</div>
          </>
        );
      },
    },
    // NO HEADER NAME ----- FIFTH TITLE CELL----

    {
      field: "editDelete",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 2,
      minWidth: 200,
      renderHeader: () => {
        return (
          <>
            <Cbtn
              className="huehuehue-white"
              type={"submit"}
              onClick={() => {
                if (deleteIds[0]) {
                  setShowDel(!showDel);
                } else {
                  alert("Select Bidders");
                }
              }}
            >
              <Deleteb
                style={{
                  marginRight: "8px",
                  marginBottom: "5px",
                }}
              />
              Delete
            </Cbtn>
          </>
        );
      },
      renderCell: (params) => {
        return (
          <>
            <Edit
              onClick={() => {
                navigate(`/bidders/${params.value.details._id}`);
              }}
              className={Styles.editSvg}
            />
            <Delete
              onClick={() => {
                setDeleteIds([params.value.details._id]);
                setShowDel(!showDel);
              }}
              className={Styles.deleteSvg}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Container>
            <Row className={Styles.rightContainer}>
              <Col md={{ span: 4 }}>
                <h1 className={Styles.mainHeading}>Team Owners</h1>
              </Col>
              <Col md={{ span: 3, offset: 5 }}>
                <Cbtn
                  className="huehuehue"
                  type={"submit"}
                  to="/bidders/new"
                  style={{
                    float: "right",
                    marginRight: "1rem",
                    // width: "100%",
                    backgroundColor: "#0040A8",
                    marginTop: "2rem",
                  }}
                >
                  <Add className={Styles.add} />
                  New Owner
                </Cbtn>
              </Col>
            </Row>

            {/* ------------------TABLE START----------- */}

            <Row className={`${Styles.table} ${Styles.rightContainer}`}>
              <DataGrid
                autoWidth
                autoHeight
                rowHeight={55}
                disableSelectionOnClick
                disableColumnFilter
                disableColumnMenu
                headerHeight={80}
                onSelectionModelChange={(ids) => {
                  setDeleteIds(ids);
                }}
                pageSize={20}
                rowsPerPageOptions={[20]}
                sx={{
                  maxHeight: "70vh",
                  borderRadius: "10px",
                  border: "none",
                  width: "calc(inherit - 100px)",
                  padding: 0,
                  boxShadow: "1px 1px 6px 1px #eaeaed",
                  "& .MuiDataGrid-main": {
                    borderRadius: "10px",
                    maxHeight: "70vh",
                    overflowY: "overlay",
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    overflow: "inherit",
                  },
                  "& .MuiCheckbox-root": {
                    color: " #DBDBDB!important",
                  },
                  "& .MuiCheckbox-root.Mui-checked": {
                    color: "#0040A8 !important",
                  },
                  "& 	.MuiDataGrid-row": {
                    backgroundColor: "#fff",
                  },
                  '& div[data-rowIndex][role="row"]': {
                    color: "#363B64",
                    fontSize: 14,
                    fontFamily: "Poppins-Medium",
                    borderBottom: "3px solid rgb(245 245 245)",
                    "& div": {
                      border: "none",
                      fontSize: 14,
                    },
                  },
                  "& 	.MuiDataGrid-cellCheckbox": {
                    width: "100px !important",
                    maxWidth: "100px !important",
                    minWidth: "100px !important",
                    marginRight: "-25px !important",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    border: "none",
                    borderBottom: "2px solid rgb(245 245 245)",

                    "& .MuiDataGrid-columnHeadersInner": {
                      backgroundColor: "#FCFCFC",

                      "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Poppins-SemiBold",
                        color: "#363B64",
                        fontSize: "14px",
                      },
                    },
                    "& .MuiDataGrid-cell--textCenter": {
                      marginLeft: "2rem",
                    },
                  },
                  "& .MuiDataGrid-cellContent": {
                    fontFamily: "Poppins-Regular !important",
                    color: "#363B64",
                    fontStyle: "1rem",
                  },

                  "& .MuiDataGrid-footerContainer": {
                    display: "none",
                    fontFamily: "Poppins-Medium",
                    borderRadius: "0px 0px 10px 10px",
                    background: "#fff !important",
                    minHeight: "35px",
                    "& .css-levciy-MuiTablePagination-displayedRows": {
                      margin: "1rem",
                    },
                  },
                  "& .MuiDataGrid-columnHeaderCheckbox, .css-1yoodjx-MuiDataGrid-root .MuiDataGrid-cellCheckbox":
                    {
                      width: "100px !important",
                      maxWidth: "100px !important",
                      minWidth: "100px !important",
                      marginRight: "-25px !important",
                    },
                }}
                loading={loading && !bidError}
                rows={bidderRow}
                columns={columns}
                checkboxSelection
              />
            </Row>
          </Container>
          <Deletefilemodal
            ids={deleteIds}
            show={showDel}
            deleteFn={deleteBidder}
            setShow={setShowDel}
          />

          {/* </Row> */}
          {/* -------------------TABLE END------------------ */}
        </ResponsiveDrawer>
      )}
    </>
  );
};

export default Bidders;
