import React, { useState } from "react";

// sass import
import Styles from "./AddPlayersToTeam.module.scss";

// importing bootstrap
import { Form, Row, Col } from "react-bootstrap";

// import svg
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DoDisturbAltOutlinedIcon from "@mui/icons-material/DoDisturbAltOutlined";
import { ReactComponent as Kaata } from "../../resources/svg/kaata.svg";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import SaveIcon from "@mui/icons-material/Save";

// importing components
import Cbtn from "../commonbutton/cbtn";
import CModal from "../modal/modal";
import { useDispatch, useSelector } from "react-redux";
import {
  Checkbox,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import SearchBar from "../searchbar/searchbar";
import {
  getBiddingPlayers,
  pushLastYearSoldPlayers,
  searchBiddingPlayers,
  searchFreePlayers,
  selectSearchBiddingPlayer,
  selectSearchFreePlayers,
  selectbiddingPlayersData,
} from "../../store/features/biddingPlayerSlice";
import { useEffect } from "react";
import { toLower } from "lodash";

export const AddPlayersToTeam = ({
  show,
  setShow,
  showError,
  setError,
  franchiseId,
  error,
}) => {
  // redux
  let dispatch = useDispatch();
  let searchedFreePlayer = useSelector(selectSearchFreePlayers);

  const [showL, setShowL] = useState();
  const [checkList, setCheckList] = useState([]);
  const [freePlayers, setFreePlayers] = useState([]);
  const [checkedPlayers, setCheckedPlayers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [recordCount, setRecordCount] = useState(50);

  let getData = async () => {
    let res = await dispatch(
      searchFreePlayers({ query: searchQuery, count: recordCount })
    );
    return res;
  };

  useEffect(() => {
    let checkedPlayersId = checkedPlayers?.map((p) => p?._id);
    let players = searchedFreePlayer
      ?.filter((player) => !player?.lastYear?.isSold)
      .filter((p) => !checkedPlayersId?.includes(p?._id));
    // console.log(searchedFreePlayer);

    setFreePlayers(players);
  }, [searchedFreePlayer]);

  // addinputkacode
  React.useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // Fetching data using query
    getData();

    let checkedPlayersId = checkedPlayers?.map((p) => p?._id);
    let players = freePlayers
      ?.filter((player) => !player?.lastYear?.isSold)
      .filter((p) => !checkedPlayersId?.includes(p?._id));

    setFreePlayers(players);
  }, [searchQuery]);

  const handleSubmit = (e) => {
    if (e?.preventDefault) e.preventDefault();
    if (checkedPlayers?.length) {
      submitForm();
    } else {
      setError("Select Players to Add");
      showError(true);
    }
  };

  const submitForm = async () => {
    let res = await dispatch(
      pushLastYearSoldPlayers({ franchiseId, freePlayers: checkedPlayers })
    );
    // console.log(res.payload);

    if (res.payload.statusCode === 200) {
      window.location.reload();
    }
    return res;
  };

  // console.log(biddingPlayerDetails);

  return (
    <CModal
      contentClassName={Styles.modalContent}
      show={show ? show : showL}
      onHide={setShow ? () => setShow(false) : () => setShowL(false)}
      title=""
      subtitle="Subtitle"
      className={Styles.cmodal}
      centered
    >
      {/* ----------------------heading-------------------------- */}

      <div
        className={Styles.Header}
        style={{ position: "relative" }}
        type="Header"
      >
        Free Players
        <button
          onClick={() => {
            setShow(false);
          }}
          style={{
            position: "absolute",
            right: 0,
            background: "transparent",
            border: "none",
          }}
        >
          <CloseRoundedIcon style={{ fontSize: "2rem" }} />
        </button>
      </div>

      <div className={Styles.Body} type="body">
        <Row
          as="form"
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
          className={`${Styles.Container} ${Styles.formContainer}`}
        >
          <Col>
            <List
              className={`${Styles.list}`}
              subheader={<li />}
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                position: "relative",
                overflow: "auto",
                maxHeight: "26rem",
                minHeight: "26rem",
                "&::webkitScrollbar": { display: "none" },
                "& ul": { padding: 0 },
              }}
            >
              <ListSubheader style={{ paddingLeft: 0 }}>
                <SearchBar
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                  sx={{
                    width: "100%",
                    paddingLeft: 0,
                    borderRadius: "8px",
                  }}
                  searchSx={
                    {
                      // padding: "12px",
                    }
                  }
                  searchColor="#b2acbd"
                  placeholder="Search Players... "
                />
              </ListSubheader>

              <ListItemButton
                alignItems="flex-start"
                role={undefined}
                sx={{
                  // marginLeft: "1rem",
                  // marginRight: "1rem",
                  cursor: "default",
                  alignItems: "center",
                }}
                dense
              >
                <ListItemIcon
                  sx={{
                    minWidth: "30px",
                  }}
                >
                  <Checkbox
                    disabled
                    // onClick={handleToggle(i, !check[i])}
                    edge="start"
                    // checked={check[i]}
                    // tabIndex={i}
                    disableRipple
                    disableFocusRipple
                    // inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    width: "5rem",
                    marginRight: ".3rem",
                    "& 	.MuiListItemText-primary": {
                      margin: "6px 0 0px 0",
                    },
                    "& 	.MuiListItemText-secondary": {
                      margin: "0px 0 4px 0",
                      lineHeight: 1,
                    },
                  }}
                  primary={
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "900",
                          fontFamily: "Poppins-Bold",
                          fontSize: "1rem",
                          // margin: "2px 0px 10px 0px",
                        }}
                      >
                        {/* {worker.name} */}
                        Player Name
                      </span>
                    </span>
                  }
                />
                <ListItemText
                  sx={{
                    width: "3.2rem",
                    "& 	.MuiListItemText-primary": {
                      margin: "6px 0 0px 0",
                    },
                    "& 	.MuiListItemText-secondary": {
                      margin: "0px 0 4px 0",
                      lineHeight: 1,
                    },
                  }}
                  primary={
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "900",
                          fontFamily: "Poppins-Bold",
                          fontSize: "1rem",
                          // margin: "2px 0px 10px 0px",
                        }}
                      >
                        {/* {worker.name} */}
                        Role
                      </span>
                    </span>
                  }
                />{" "}
                <ListItemText
                  sx={{
                    width: "4rem",
                    "& 	.MuiListItemText-primary": {
                      margin: "6px 0 0px 0",
                    },
                    "& 	.MuiListItemText-secondary": {
                      margin: "0px 0 4px 0",
                      lineHeight: 1,
                    },
                  }}
                  primary={
                    <span
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "900",
                          fontFamily: "Poppins-Bold",
                          fontSize: "1rem",
                          // margin: "2px 0px 10px 0px",
                        }}
                      >
                        {/* {worker.name} */}
                        Nationality
                      </span>
                    </span>
                  }
                />
              </ListItemButton>
              <Divider style={{}} light component={"li"} varient="middle" />
              {checkedPlayers?.map((player, i) => (
                <>
                  <ListItemButton
                    key={i}
                    alignItems="flex-start"
                    role={undefined}
                    sx={{
                      // marginLeft: "1rem",
                      // marginRight: "1rem",
                      cursor: "default",
                      alignItems: "center",
                    }}
                    dense
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                      }}
                    >
                      <Checkbox
                        onClick={() => {
                          setFreePlayers((prev) => [...prev, player]);
                          setCheckedPlayers((prev) => {
                            let arr = prev?.map((p) => p?._id);
                            let index = arr.indexOf(player?._id);
                            prev.splice(index, 1);
                            return prev;
                          });
                        }}
                        edge="start"
                        checked={true}
                        tabIndex={i}
                        disableRipple
                        disableFocusRipple
                        // inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        marginRight: ".3rem",

                        width: "5rem",

                        "& 	.MuiListItemText-primary": {
                          margin: "6px 0 0px 0",
                        },
                        "& 	.MuiListItemText-secondary": {
                          margin: "0px 0 4px 0",
                          lineHeight: 1,
                        },
                      }}
                      primary={
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "900",
                              fontFamily: "Poppins-Regular",
                              fontSize: "1rem",
                              // margin: "2px 0px 10px 0px",
                            }}
                          >
                            {/* {worker.name} */}
                            {player?.name || "Player Name"}
                          </span>
                        </span>
                      }
                    />
                    <ListItemText
                      sx={{
                        width: "3.2rem",
                        "& 	.MuiListItemText-primary": {
                          margin: "6px 0 0px 0",
                        },
                        "& 	.MuiListItemText-secondary": {
                          margin: "0px 0 4px 0",
                          lineHeight: 1,
                        },
                      }}
                      primary={
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "900",
                              fontFamily: "Poppins-Regular",
                              fontSize: "1rem",
                              // margin: "2px 0px 10px 0px",
                            }}
                          >
                            {/* {worker.name} */}
                            {player?.role || " Role"}
                          </span>
                        </span>
                      }
                    />{" "}
                    <ListItemText
                      sx={{
                        width: "4rem",
                        "& 	.MuiListItemText-primary": {
                          margin: "6px 0 0px 0",
                        },
                        "& 	.MuiListItemText-secondary": {
                          margin: "0px 0 4px 0",
                          lineHeight: 1,
                        },
                      }}
                      primary={
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "900",
                              fontFamily: "Poppins-Regular",
                              fontSize: "1rem",
                              // margin: "2px 0px 10px 0px",
                            }}
                          >
                            {/* {worker.name} */}

                            {player?.nationality || "Nationality"}
                          </span>
                        </span>
                      }
                    />
                  </ListItemButton>
                  <Divider style={{}} light component={"li"} varient="middle" />
                </>
              ))}
              {freePlayers?.map((player, i) => (
                <>
                  <ListItemButton
                    key={i}
                    alignItems="flex-start"
                    role={undefined}
                    sx={{
                      // marginLeft: "1rem",
                      // marginRight: "1rem",
                      cursor: "default",
                      alignItems: "center",
                    }}
                    dense
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: "30px",
                      }}
                    >
                      <Checkbox
                        onClick={() => {
                          setCheckedPlayers((prev) => [...prev, player]);
                          setFreePlayers((prev) => {
                            let arr = prev?.map((p) => p?._id);
                            let index = arr.indexOf(player?._id);
                            prev.splice(index, 1);
                            return prev;
                          });
                        }}
                        edge="start"
                        checked={false}
                        tabIndex={i}
                        disableRipple
                        disableFocusRipple
                        // inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        marginRight: ".3rem",

                        width: "5rem",

                        "& 	.MuiListItemText-primary": {
                          margin: "6px 0 0px 0",
                        },
                        "& 	.MuiListItemText-secondary": {
                          margin: "0px 0 4px 0",
                          lineHeight: 1,
                        },
                      }}
                      primary={
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "900",
                              fontFamily: "Poppins-Regular",
                              fontSize: "1rem",
                              // margin: "2px 0px 10px 0px",
                            }}
                          >
                            {/* {worker.name} */}
                            {player?.name || "Player Name"}
                          </span>
                        </span>
                      }
                    />
                    <ListItemText
                      sx={{
                        width: "3.2rem",
                        "& 	.MuiListItemText-primary": {
                          margin: "6px 0 0px 0",
                        },
                        "& 	.MuiListItemText-secondary": {
                          margin: "0px 0 4px 0",
                          lineHeight: 1,
                        },
                      }}
                      primary={
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "900",
                              fontFamily: "Poppins-Regular",
                              fontSize: "1rem",
                              // margin: "2px 0px 10px 0px",
                            }}
                          >
                            {/* {worker.name} */}
                            {player?.role || " Role"}
                          </span>
                        </span>
                      }
                    />{" "}
                    <ListItemText
                      sx={{
                        width: "4rem",
                        "& 	.MuiListItemText-primary": {
                          margin: "6px 0 0px 0",
                        },
                        "& 	.MuiListItemText-secondary": {
                          margin: "0px 0 4px 0",
                          lineHeight: 1,
                        },
                      }}
                      primary={
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span
                            style={{
                              fontWeight: "900",
                              fontFamily: "Poppins-Regular",
                              fontSize: "1rem",
                              // margin: "2px 0px 10px 0px",
                            }}
                          >
                            {/* {worker.name} */}

                            {player?.nationality || "Nationality"}
                          </span>
                        </span>
                      }
                    />
                  </ListItemButton>
                  <Divider style={{}} light component={"li"} varient="middle" />
                </>
              ))}
            </List>
          </Col>
        </Row>
      </div>
      <div type="footer">
        <Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Cbtn
              type="submit"
              onClick={handleSubmit}
              style={{
                width: "8rem",
                fontSize: "0.8rem",
                borderRadius: "0.5rem",
                backgroundColor: "#0040A8",
              }}
            >
              <PersonAddIcon
                fontSize="small"
                sx={{ width: " 1.1rem", height: " 1.1rem", mr: "5px" }}
              />
              <span style={{ margin: "1px" }}>Add</span>
            </Cbtn>
          </Col>
          <Col>
            <Cbtn
              style={{
                width: "8rem",
                borderRadius: "0.5rem",
                fontSize: ".8rem",
                backgroundColor: "#363b64",
              }}
              onClick={() => {
                setShow(false);
              }}
            >
              <DoDisturbAltOutlinedIcon
                fontSize="small"
                sx={{ width: " 1rem", height: " 1rem", mr: "5px" }}
              />
              <span style={{ margin: "1px" }}>Discard</span>
            </Cbtn>
          </Col>
        </Row>
      </div>

      {/* ------------------------------FOOTER-------------------------------- */}
    </CModal>
  );
};
