import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import bidderRoutes from "../network routes/bidders";

export const getBidder = createAsyncThunk("bidder/getBidder", async () => {
  const response = await bidderRoutes.getBidders();
  return response;
});

export const getFreeBidder = createAsyncThunk(
  "bidder/getFreeBidder",
  async () => {
    const response = await bidderRoutes.getFreeBidders();
    return response;
  }
);

export const getBidderById = createAsyncThunk(
  "bidder/getBidderById",
  async (id) => {
    const response = await bidderRoutes.getBiddersById(id);
    return response;
  }
);

export const createBidder = createAsyncThunk(
  "bidder/createBidder",
  async (data) => {
    // console.log(data);
    const response = await bidderRoutes.createBidder(data);
    return response;
  }
);

export const editBidder = createAsyncThunk(
  "bidder/editBidder",
  async (data) => {
    const response = await bidderRoutes.editBidder(data);
    return response;
  }
);

export const deleteBidder = createAsyncThunk(
  "bidder/deleteBidder",
  async (id) => {
    const response = await bidderRoutes.deleteBidder(id);
    return response;
  }
);

export const assignFranchise = createAsyncThunk(
  "bidder/assignFranchise",
  async (data) => {
    const response = await bidderRoutes.assignFranchise(data);
    return response;
  }
);

export const removeFranchise = createAsyncThunk(
  "bidder/removeFranchise",
  async (id) => {
    const response = await bidderRoutes.removeFranchise(id);
    return response;
  }
);

const bidderSlice = createSlice({
  name: "bidder",
  initialState: {
    data: [],
    dataById: {},
    freeBidder: [],
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getBidder.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data[0]) {
        state.data = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getBidder.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getFreeBidder.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      // console.log(data);
      if (statusCode === 200 && data[0]) {
        state.freeBidder = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getFreeBidder.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getBidderById.fulfilled]: (state, action) => {
      let { statusCode, data } = action.payload.data;
      if (statusCode === 200) {
        state.dataById = data;
      } else {
        state.error = "Login first";
      }
    },
    [getBidderById.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createBidder.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.data.push(action.payload.data);
      } else {
        state.error = "Login first";
      }
    },
    [createBidder.rejected]: (state, action) => {
      state.error = action.error;
    },
    [editBidder.fulfilled]: (state, action) => {
      // // console.log(action);
      if (action.payload.statusCode === 200) {
        let index = state.data.findIndex(
          (ele) => ele._id === action.payload.data._id
        );
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [editBidder.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteBidder.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        if (state.data) {
          //   let index = state.data.findIndex(
          //     (ele) => ele._id === action.payload.data._id
          //   );
          //   state.data[index] = action.payload.data;
        } else {
          state.error = "Login first";
        }
      }
    },
    [deleteBidder.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const selectFreeBidderData = (state) => state.bidder.freeBidder;
export const selectbidderData = (state) => state.bidder.data;
export const selectBidderError = (state) => state.bidder.error;
export const selectBidderDataById = (state) => state.bidder.dataById;
export default bidderSlice.reducer;
