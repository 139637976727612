import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import FileSaver from "file-saver";
import SaveIcon from "@mui/icons-material/Save";

//link
import { Link } from "react-router-dom";

//svg import
import { ReactComponent as Dashboard } from "../../resources/svg/dashboard.svg";
import { ReactComponent as Sequences } from "../../resources/svg/Mail.svg";
import { ReactComponent as Backup } from "../../resources/svg/backup.svg";
import { ReactComponent as Raw } from "../../resources/svg/raw_materials.svg";
import { ReactComponent as Job } from "../../resources/svg/job.svg";
import { ReactComponent as Workers } from "../../resources/svg/Team.svg";
import { ReactComponent as Chat } from "../../resources/svg/chat.svg";
import { ReactComponent as Settings } from "../../resources/svg/setting.svg";
import { createTheme, ThemeProvider } from "@mui/material/styles";

//scss import
import ResponsiveDrawerStyle from "./responsiveDrawer.module.scss";

import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import TopNavBar from "../topnav/topNav";
import Cbtn from "../commonbutton/cbtn";
import { checkLoginStatus } from "../../store/features/adminSlice";
import { useDispatch } from "react-redux";
import instance from "../../store/network routes/instance";

const drawerWidth = 280;
const AppbarHeight = 80;

export default function ResponsiveDrawer({ show, setshow, children }) {
  let dispatch = useDispatch();

  const defaultTheme = createTheme();
  const theme = createTheme({
    breakpoints: {
      values: {
        ...defaultTheme.breakpoints.values,

        md: 768,
      },
    },
  });
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  // state
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  // render
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ display: "flex" }}>
        <AppBar
          position="fixed"
          sx={(theme) => ({
            zIndex: "998",
            width: isLgUp ? { sm: `calc(100% - ${drawerWidth}px)` } : "100%",
            ml: isLgUp ? { sm: `${drawerWidth}px` } : "auto",
            height: isMdUp ? AppbarHeight : AppbarHeight * 2,
            backgroundColor: "#fff",
            boxShadow: "0px 5px 30px #0000000D",
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
              sx={{ display: isLgUp ? "none" : "block", color: "#363B64" }}
            >
              <MenuIcon />
            </IconButton>
            <TopNavBar
              show={show}
              setshow={setshow}
              isLgUp={isLgUp}
              isMdUp={isMdUp}
              isSmUp={isSmUp}
            />
          </Toolbar>
        </AppBar>
        <div
          className={ResponsiveDrawerStyle.content}
          style={{
            // position: "static",
            width: isLgUp ? `calc(100% - ${drawerWidth}px)` : "100%",
            marginLeft: isLgUp ? drawerWidth : "0px",
            marginTop: isMdUp ? AppbarHeight : AppbarHeight * 2,

            backgroundColor: "#f5f5f5",
            minHeight: `calc(100vh - ${AppbarHeight}px)`,
          }}
        >
          {children}
        </div>
        <Drawer
          className={ResponsiveDrawerStyle.drawer}
          sx={(theme) => ({
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              background: "#FFFFFF",
              zIndex: "998",
            },
          })}
          variant={isLgUp ? "permanent" : "temporary"}
          classes={{
            paper: ResponsiveDrawerStyle.bg,
          }}
          anchor="left"
          open={open}
          onClose={toggleDrawer}
        >
          <Box
            component="div"
            sx={(theme) => ({
              ...theme.mixins.toolbar,
              [theme.breakpoints.down("xs")]: {
                display: "none",
              },
            })}
          >
            <Toolbar
              sx={{
                textAlign: "center",
                color: "#0040A8",
                fontFamily: "Poppins-Bold",
                fontSize: "29px",
                mx: "2rem",
                mt: "2rem",
                textTransform: "uppercase",
              }}
            >
              {" "}
              AUCTION
            </Toolbar>

            <List>
              {[
                {
                  text: "Home",
                  link: "/",
                  icon: (
                    <Dashboard
                      id="Dashboardactive"
                      className={ResponsiveDrawerStyle.icon}
                      style={{ height: "1rem" }}
                    />
                  ),
                },

                {
                  text: "Team Owners",
                  link: "/bidders",
                  icon: (
                    <Sequences
                      id="Sequencesactive"
                      className={ResponsiveDrawerStyle.icon}
                      style={{ height: "1rem" }}
                    />
                  ),
                },
                {
                  text: "Teams",
                  link: "/teams",
                  icon: (
                    <Workers
                      id="Workersactive"
                      className={ResponsiveDrawerStyle.icon}
                      style={{ height: "1rem" }}
                    />
                  ),
                },
                {
                  text: "Curators",
                  link: "/curators",
                  icon: (
                    <Job
                      id="Jobsactive"
                      className={ResponsiveDrawerStyle.icon}
                      style={{ height: "1rem" }}
                    />
                  ),
                },
                {
                  text: "Players",
                  link: "/biddingPlayers",

                  icon: (
                    <Workers
                      id="Workersactive"
                      className={ResponsiveDrawerStyle.icon}
                      style={{ height: "1rem" }}
                    />
                  ),
                },
              ].map((obj, index) => (
                <Link
                  to={obj.link}
                  key={index}
                  style={{ textDecoration: "none", display: "flex" }}
                >
                  {`/${window.location.href.split("/")[3]}` === obj.link ? (
                    <>
                      <style>
                        {`
                      #${obj.text}active{
                        fill:white;
                        stroke:white;
                      }
                      #${obj.text}active g{
                        fill:white;
                        stroke:white;
                      }
                      #${obj.text}active g g{
                        fill:white !important;
                        stroke:white;
                      }
                    `}
                      </style>
                      <ListItem
                        className={`${ResponsiveDrawerStyle.listItem} ${ResponsiveDrawerStyle.active}`}
                        // className={ResponsiveDrawerStyle.listItem}
                        sx={{
                          padding: "0.5rem 1rem",
                          backgroundColor: "#0040A8",
                          color: "#fff",
                          margin: "auto",
                          maxWidth: "13rem",
                          borderRadius: "8px",
                          justifyContent: "flex-start",
                        }}
                        button
                        key={obj.text}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "44px",
                          }}
                        >
                          {obj.icon}
                        </ListItemIcon>

                        <ListItemText
                          className={`${ResponsiveDrawerStyle.listItemText} ${ResponsiveDrawerStyle.active}`}
                          sx={{
                            "& .MuiListItemText-primary": {
                              fontFamily: "Poppins-Regular",
                              fontSize: "15px",
                              color: "#fff",
                            },
                          }}
                          primary={obj.text}
                        />
                      </ListItem>
                    </>
                  ) : (
                    <>
                      <ListItem
                        className={ResponsiveDrawerStyle.listItem}
                        sx={{
                          padding: "0.8rem 1rem",
                          margin: "auto",
                          maxWidth: "13rem",
                          borderRadius: "10px",
                          justifyContent: "flex-start",
                        }}
                        button
                        key={obj.text}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "44px",
                          }}
                        >
                          {obj.icon}
                        </ListItemIcon>

                        <ListItemText
                          className={ResponsiveDrawerStyle.listItemText}
                          sx={{
                            "& .MuiListItemText-primary": {
                              fontFamily: "Poppins-Regular",
                              fontSize: "15px",
                              color: "#a098ae",
                              "&.hover": {
                                color: "#fff",
                              },
                            },
                          }}
                          primary={obj.text}
                        />
                      </ListItem>
                    </>
                  )}
                </Link>
              ))}

              {/* <Link
                to={"#"}
                style={{ textDecoration: "none", display: "flex" }}
                onClick={async () => {
                  let res = instance({
                    responseType: "arraybuffer",
                    method: "get",
                    url: "mongoBackup",
                    headers: {
                      "Content-Type": "application/gzip",
                    },
                  });
                  const blob = new Blob([res.data], {
                    type: "application/gzip",
                  });
                  FileSaver.saveAs(blob, "backup.gzip");
                }}
              >
                <ListItem
                  className={ResponsiveDrawerStyle.listItem}
                  sx={{
                    padding: "0.8rem 1rem",
                    margin: "auto",
                    maxWidth: "13rem",
                    borderRadius: "10px",
                    justifyContent: "flex-start",
                  }}
                  button
                  key={"Restore"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: "44px",
                    }}
                  >
                    {<></>}
                  </ListItemIcon>

                  <ListItemText
                    className={ResponsiveDrawerStyle.listItemText}
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontFamily: "Poppins-Regular",
                        fontSize: "15px",
                        color: "#a098ae",
                        "&.hover": {
                          color: "#fff",
                        },
                      },
                    }}
                    primary={"Restore"}
                  />
                </ListItem>
              </Link> */}
            </List>
            <a
              href="https://shop.spectors.in/policies/privacy-policy"
              className={ResponsiveDrawerStyle.privacypolicy}
            >
              privacypolicy
            </a>
          </Box>
        </Drawer>
      </div>
    </ThemeProvider>
  );
}
