import React, { useEffect, useState } from "react";
//scss
import EditCuratorStyle from "./editCurator.module.scss";

//bootstrap
import { Form, Container, Row, Col } from "react-bootstrap";

//svg
import { ReactComponent as Save } from "../../resources/svg/save.svg";

//component
import ResponsiveDrawer from "../../components/responsiveDrawer/responsiveDrawer";
import Cbtn from "../../components/commonbutton/cbtn";
import Snackbar from "../../components/snackbar/snackbar";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import CancelIcon from "@mui/icons-material/Cancel";
import instance from "../../store/network routes/instance";
import { selectStatus } from "../../store/features/adminSlice";
import { selectFreeFranchiseData } from "../../store/features/franchiseSlice";
import {
  editCurator,
  getCuratorById,
  selectCuratorDataById,
} from "../../store/features/curatorSlice";

function EditCurator() {
  const { id } = useParams();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [editCuratorDetail, setEditCuratorDetail] = useState({
    name: "",
    email: "",
    userId: "",
    password: "",
    image: "",
  });
  const [error, setError] = React.useState("");
  const [showError, setShowError] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [franchiseOptions, setFranchiseOptions] = useState([]);
  let franchises = useSelector(selectFreeFranchiseData);
  const curator = useSelector(selectCuratorDataById);

  const sendFile = async (file) => {
    let bodyFormData = new FormData();
    setDisableSubmit(true);
    bodyFormData.append("file", file);
    try {
      if (bodyFormData.get("file")) {
        let res = await instance({
          method: "email",
          url: "uploadFile",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        });
        if (res.data.statusCode === 200) {
          setDisableSubmit(false);
          return res.data.url;
        } else {
        }
      }
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        alert("File size should be less than 50mb");
        setDisableSubmit(false);
        document.getElementById("userIdProof").value = "";
        setEditCuratorDetail((prev) => ({
          ...prev,
          userIdProof: "",
        }));
      }
    }
  };

  const handleChange = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditCuratorDetail({
        ...editCuratorDetail,
        image: res,
      });
    }
  };

  React.useEffect(() => {
    if (id) {
      dispatch(getCuratorById(id));
    }
  }, [id]);

  React.useEffect(() => {
    if (curator) {
      // console.log(curator);
      setEditCuratorDetail(curator);
    }
  }, [curator]);

  const handleChange1 = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditCuratorDetail({
        ...editCuratorDetail,
        userIdProof: res,
      });
    }
  };

  const handleFormChange = (e, value) => {
    setEditCuratorDetail({
      ...editCuratorDetail,
      [e.target.name]: value || e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = false;

    const { name, email, userId } = editCuratorDetail;

    validate =
      Object.values({
        name,
        email,
      }).every(Boolean) ||
      Object.values({
        name,
        userId,
      }).every(Boolean);

    if (validate) {
      submitForm(editCuratorDetail);
    } else {
      setError("Enter Full Details");
      setShowError(true);
      validate = false;
    }
  };

  const submitForm = async (data) => {
    const res = await dispatch(editCurator(data));
    if (res.payload.statusCode === 201) {
      setEditCuratorDetail({
        name: "",
        email: "",
        userId: "",
        password: "",
        image: "",
      });
      navigate("/curators");
    }
    return res;
  };
  const loadingStatus = useSelector(selectStatus);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showError}
            setShow={setShowError}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          ></Snackbar>
          <Container className={EditCuratorStyle.container}>
            <Row className={EditCuratorStyle.Container}>
              <Col>
                <h1 className={EditCuratorStyle.mainHeading}>Edit Curators</h1>
              </Col>
            </Row>
            <Row
              className={`${EditCuratorStyle.Container} ${EditCuratorStyle.formContainer}`}
            >
              <Form onSubmit={handleSubmit}>
                {/* <Row>
                  <Col>
                    <input
                      type="file"
                      className={EditCuratorStyle.customFileInput}
                      accept="image/*"
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        background: editCuratorDetail.image
                          ? `url(${editCuratorDetail.image}) #CBCBCB center center/cover`
                          : "#CBCBCB",
                        height: "110px",
                        width: "110px",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "-60px",
                        right: "70px",
                        zIndex: 2,
                      }}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Form.Group as={Col} className="mb-4" controlId="name">
                    <Form.Label className={EditCuratorStyle.label}>
                      Name
                    </Form.Label>
                    <Form.Control
                      className={EditCuratorStyle.control}
                      type="name"
                      name="name"
                      defaultValue={""}
                      onChange={handleFormChange}
                      placeholder="name"
                      value={editCuratorDetail.name}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="userId">
                    <Form.Label className={EditCuratorStyle.label}>
                      Username
                    </Form.Label>
                    <Form.Control
                      // id={""}
                      className={EditCuratorStyle.control}
                      type="text"
                      name="userId"
                      defaultValue={""}
                      placeholder="username"
                      onChange={handleFormChange}
                      value={editCuratorDetail.userId}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-4" controlId="email">
                    <Form.Label className={EditCuratorStyle.label}>
                      Email
                    </Form.Label>
                    <Form.Control
                      type="email"
                      autocomplete="off"
                      placeholder="John Doe"
                      name="email"
                      defaultValue={""}
                      className={EditCuratorStyle.control}
                      value={editCuratorDetail.email}
                      onChange={handleFormChange}
                    />
                  </Form.Group>

                  <Form.Group as={Col} className="mb-4" controlId="password">
                    <Form.Label className={EditCuratorStyle.label}>
                      Password
                    </Form.Label>
                    <Form.Control
                      className={EditCuratorStyle.control}
                      type="password"
                      autocomplete="off"
                      name="password"
                      defaultValue={""}
                      onChange={handleFormChange}
                      value={editCuratorDetail.password}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col xl={6}>
                    <Cbtn
                      disabled={disableSubmit}
                      type="submit"
                      style={{
                        fontSize: "0.8rem",
                        backgroundColor: disableSubmit ? "grey" : "#0040A8",
                        color: disableSubmit ? "black" : "#fff",
                        margin: "auto",
                        float: "right",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                        padding: "11px 34px",
                      }}
                    >
                      <Save
                        style={{ marginRight: "5px" }}
                        className={EditCuratorStyle.save}
                      />
                      Save
                    </Cbtn>
                  </Col>
                  <Col xl={6}>
                    <Cbtn
                      to="/curators"
                      disabled={disableSubmit}
                      type="button"
                      style={{
                        backgroundColor: "rgb(57 42 42)",
                        color: "white",
                        margin: "auto",
                        fontSize: "0.8rem",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                      }}
                    >
                      Discard
                    </Cbtn>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}

export default EditCurator;
