import instance from "./instance";

class BiddingPlayerService {
  getBiddingPlayers = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "getBiddingPlayers",
    });
    return data;
  };

  getBiddingPlayersById = async (id, token) => {
    let res = await instance({
      method: "get",
      url: `getBiddingPlayers/${id}`,
    });
    return res;
  };

  searchFreePlayers = async ({ query, count }, token) => {
    let res = await instance({
      method: "post",
      url: "/search/BiddingPlayers/free",
      data: { query, count },
    });
    return res.data;
  };

  pushLastYearSoldPlayers = async ({ franchiseId, freePlayers }, token) => {
    let res = await instance({
      method: "post",
      url: "/pushLastYearSoldPlayers",
      data: { franchiseId, freePlayers },
    });
    return res.data;
  };

  createBiddingPlayer = async (data) => {
    let resdata = await instance({
      method: "post",
      url: "createBiddingPlayer",
      data,
    });
    return resdata.data;
  };

  editBiddingPlayer = async (data) => {
    let res = await instance({
      method: "put",
      url: "editBiddingPlayer/" + data._id,
      data,
    });
    return res.data;
  };

  assignFranchise = async (data, token) => {
    let res = await instance({
      method: "put",
      url: "assignFranchise/" + data.id,
      data,
    });
    return res.data;
  };

  deleteBiddingPlayer = async (id, token) => {
    let res = await instance({
      method: "delete",
      url: "deleteBiddingPlayer/" + id,
    });
    return res.data;
  };

  getLastYearPlayers = async (id, token) => {
    let res = await instance({
      method: "get",
      url: `getLastYearPlayers/${id}`,
    });
    return res;
  };

  fetchResetData = async (token) => {
    let res = await instance({
      method: "post",
      url: "fetchResetData",
    });
    return res.data;
  };
}

export default new BiddingPlayerService();
