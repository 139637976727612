import instance from "./instance";

class BidderService {
  getBidders = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "getBidders",
    });
    return data;
  };

  getFreeBidders = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "getFreeBidders",
    });
    return data;
  };

  getBiddersById = async (id, token) => {
    // console.log(id);
    let res = await instance({
      method: "get",
      url: `getBidders/${id}`,
    });
    return res;
  };

  searchBidder = async (data, token) => {
    let res = await instance({
      method: "get",
      url: "search/bidder",
      data,
    });
    return res.data;
  };

  createBidder = async (data) => {
    // console.log(data);
    let resdata = await instance({
      method: "post",
      url: "createBidder",
      data,
    });
    return resdata.data;
  };

  editBidder = async (data) => {
    let res = await instance({
      method: "put",
      url: "editBidder/" + data._id,
      data,
    });
    return res.data;
  };

  assignFranchise = async (data, token) => {
    let res = await instance({
      method: "put",
      url: "assginFranchise/" + data._id,
      data,
    });
    return res.data;
  };

  removeFranchise = async (id, token) => {
    let res = await instance({
      method: "put",
      url: "removeFranchise/" + id,
    });
    return res.data;
  };

  deleteBidder = async (id, token) => {
    let res = await instance({
      method: "delete",
      url: "deleteBidder/" + id,
    });
    return res.data;
  };
}

export default new BidderService();
