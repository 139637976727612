import React, { useState } from "react";

// importing styles
import Styles from "./deletefilemodal.module.scss";

// importing bootstrap
import { Button, Row, Col } from "react-bootstrap";

// importing components
import Cbtn from "../commonbutton/cbtn";
import CModal from "../modal/modal";

// import svg
import { ReactComponent as Delete } from "../../resources/svg/Trash.svg";
import DeletePass from "../deletepassmodal/deletepass";
import { useDispatch } from "react-redux";

function Deletefilemodal({ show, setShow, ids, deleteFn }) {
  const [askPass, setAskPass] = useState(false);
  const dispatch = useDispatch();

  const deleteEverything = (ids) => {
    let res;
    ids.forEach(async (id, i, arr) => {
      res = await dispatch(deleteFn(id));
      if (i === arr.length - 1) {
        window.location.reload();
      }
    });
    return res;
  };

  return (
    <>
      <CModal
        show={show}
        onHide={() => setShow(false)}
        title=""
        subtitle="osinvoin"
        className={Styles.cmodal}
        centered
        contentClassName={Styles.content}
      >
        <div className={Styles.Header} type="Header">
          <Delete style={{ paddingRight: "4px", paddingBottom: "4px" }} />
          Delete File
        </div>
        <div className={Styles.subtitle} type="subtitle">
          Please Confirm to Delete file
        </div>

        <Row className={Styles.Footer} type="footer">
          <Col>
            <Cbtn
              onClick={() => {
                setShow(false);
              }}
              style={{
                width: "100%",
                backgroundColor: "#4b4d51",
                fontSize: "16px",
              }}
            >
              No
            </Cbtn>
          </Col>
          <Col>
            <Cbtn
              onClick={async () => {
                if (ids[0]) {
                  await deleteEverything(ids);
                } else {
                  alert("Nothing to delete");
                }
              }}
              style={{
                width: "100%",
                backgroundColor: "#0040A8",
                fontSize: "16px",
              }}
            >
              Yes
            </Cbtn>
            {askPass && (
              <DeletePass
                show={askPass}
                setShow={setAskPass}
                setDelShow={setShow}
              ></DeletePass>
            )}
          </Col>
        </Row>
      </CModal>
    </>
  );
}

export default Deletefilemodal;
