import * as React from "react";

// import { useDemoData } from "@mui/x-data-grid-generator";
import { DataGrid } from "@mui/x-data-grid";

import { CircularProgress, Typography } from "@mui/material";

//svg
import { ReactComponent as Add } from "../../resources/svg/Add.svg";
import { ReactComponent as Deleteb } from "../../resources/svg/buetrash.svg";

//png
import pp from "../../resources/images/background.png";
import refresh from "../../resources/images/refresh_logo.png";

//scss
import BiddingPlayersStyle from "./biddingPlayers.module.scss";
import { ReactComponent as Edit } from "../../resources/svg/Group 79.svg";

//bootstrap
import { Container, Row, Col } from "react-bootstrap";

//component
import Deletefilemodal from "../../components/deletfilemodal/deletefilemodal";
import MouseOverPopover from "../../components/popover/popover";
import ResponsiveDrawer from "../../components/responsiveDrawer/responsiveDrawer";
import SelectLabels from "../../components/dropdowns/1/dropdown1";
import Cbtn from "../../components/commonbutton/cbtn";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Assignbundle from "../../components/assignbundlemodal/assignbundle";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import { useSelector } from "react-redux";
import { selectStatus } from "../../store/features/adminSlice";
import { useDispatch } from "react-redux";
import { Details } from "@mui/icons-material";
import SearchBar from "../../components/searchbar/searchbar";
import {
  deleteBiddingPlayer,
  fetchResetData,
  getBiddingPlayers,
  selectbiddingPlayersData,
} from "../../store/features/biddingPlayerSlice";

export default function BiddingPlayers() {
  //react-router-dom hooks
  let navigate = useNavigate();
  let location = useLocation();

  //use Selector and useDispatch hooks
  let loadingStatus = useSelector(selectStatus);
  let dispatch = useDispatch();

  //useState hooks
  const [balanceLoader, setBalanceLoader] = React.useState([false]);
  const [loading, setLoading] = React.useState(true);
  const [query, setQuery] = React.useState("");
  const [error, setError] = React.useState("");
  const [deleteIds, setDeleteIds] = React.useState([]);
  const [showToast, setShowToast] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [showDel, setShowDel] = React.useState(false);
  const [assignModel, setAssignModel] = React.useState({});
  const [biddingPlayerRows, setBiddingPlayerRows] = React.useState([
    // {
    //   id: 1,
    //   profile: "",
    //   name: {
    //     name: ``,
    //     id: 1,
    //   },
    //   count: 1,
    //   nationality: "",
    //   role: "",
    //   battingHanded: "",
    //   bowlingHanded: "",
    //   marquee: {
    //     status: "No",
    //     id: 1,
    //   },
    //   elite: {
    //     status: "No",
    //     id: 1,
    //   },
    //   basePrice: "",
    //   delete: 1,
    // },
  ]);

  let getData = async () => {
    let res = await dispatch(getBiddingPlayers());
    setLoading(false);
    return res;
  };

  let biddingPlayerDetails = useSelector(selectbiddingPlayersData);

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (biddingPlayerDetails[0]) {
      let tempData = [...biddingPlayerDetails];
      let queryArr = query.toLowerCase().split(" ");
      queryArr.forEach((query) => {
        if (query) {
          tempData = tempData.filter(
            ({ name, role, battingHanded, bowlingHanded, nationality }) => {
              return (
                name?.toLowerCase()?.includes(query?.toLowerCase()) ||
                role?.toLowerCase()?.includes(query?.toLowerCase()) ||
                battingHanded?.toLowerCase()?.includes(query?.toLowerCase()) ||
                nationality?.toLowerCase()?.includes(query?.toLowerCase()) ||
                bowlingHanded?.toLowerCase()?.includes(query?.toLowerCase())
              );
            }
          );
        }
      });

      setBiddingPlayerRows(() =>
        tempData.map(
          (
            {
              image,
              name,
              marquee,
              _id,
              role,
              nationality,
              basePriceString,
              battingHanded,
              bowlingHanded,
              elite,
            },
            i
          ) => ({
            id: _id,
            _id,
            profile: image,
            name: {
              name: name?.split(" ")[1]
                ? `${name?.split(" ")[0][0]}. ${name?.split(" ")[1]}`
                : name?.split(" ")[0],
              id: _id,
            },
            count: i + 1,
            nationality,
            role,
            battingHanded,
            bowlingHanded,
            marquee: {
              status: marquee ? "Yes" : "No",
              id: _id,
            },
            elite: {
              status: elite ? "Yes" : "No",
              id: _id,
            },
            delete: _id,
            basePrice: basePriceString,
            editDelete: {
              details: {
                image,
                marquee: {
                  status: marquee ? "Yes" : "No",
                  id: _id,
                },
                _id,
                role,
                nationality,
                basePriceString,
                battingHanded,
                bowlingHanded,
                elite: {
                  status: elite ? "Yes" : "No",
                  id: _id,
                },
              },
              show: false,
              setShow: (value) => {
                setBiddingPlayerRows((prev) => {
                  let temp = [...prev];
                  temp[i].editDelete.show = value;
                  return temp;
                });
              },
            },
          })
        )
      );
    }
  }, [biddingPlayerDetails, query]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  //dispatch action from reducer

  //1. getBiddingPlayer

  // 2.deleteBiddingPlayer
  const deleteBiddingPlayerRow = async (id) => {
    // const res = await dispatch(deleteBiddingPlayers(id));
    // return res;
  };

  //useEffect hooks

  //1. location
  React.useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  //2. check whether the user is logged in or not
  React.useEffect(() => {
    if (loadingStatus === "error") {
      navigate("/login");
    }
  }, [loadingStatus]);

  //3.getBiddingPlayers called

  const columns = [
    // {
    //   field: "profile",
    //   headerName: "Profile",
    //   flex: 1,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div
    //         style={{
    //           background: `url(${params.value}) center center/cover`,
    //           width: "100%",
    //           height: "auto",
    //         }}
    //       />
    //     );
    //   },
    // },
    {
      field: "name",
      flex: 2,
      sortable: true,
      renderHeader: () => (
        <div
          style={{
            fontFamily: "Poppins-Medium",
            display: "flex",
            width: "100%",
            marginLeft: "1rem",
            justifyContent: "space-between",
          }}
        >
          Name
        </div>
      ),
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                fontFamily: "Poppins-Medium",
                display: "flex",
                width: "100%",
                marginLeft: "1rem",
                justifyContent: "space-between",
              }}
            >
              <div>{params?.value?.name}</div>
              <div>
                <Link to={`/biddingPlayers/${params?.value.id}`}>
                  <Edit className={BiddingPlayersStyle.edit} />
                </Link>
              </div>
            </div>
          </>
        );
      },
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
    },
    {
      field: "nationality",
      headerName: "Nationality",
      flex: 1.5,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                fontFamily: "Poppins-Medium",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div>{params.value}</div>
            </div>
          </>
        );
      },
      sortComparator: (v1, v2) =>
        v1.nameBiddingPlayer.localeCompare(v2.nameBiddingPlayer),
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1.5,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                fontFamily: "Poppins-Medium",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div>{params.value}</div>
            </div>
          </>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "elite",
      headerName: "Elite",
      flex: 1,
      renderCell: (params) => {
        let temp = [{ option: "Yes" }, { option: "No" }];
        return (
          <div style={{ float: "right" }}>
            <SelectLabels
              disabled
              selectBg="transparent"
              slist={temp}
              active={params.value.status}
              setActive={(event) => {
                setBiddingPlayerRows((prev) => {
                  return prev.map((e) => {
                    if (e.id === params.value.id) {
                      // editBiddingPlayer({
                      //   _id: params.value.id,
                      //   status:
                      //     event.target.value === "Enabled" ? true : false,
                      // })
                      return {
                        ...e,
                        Status: {
                          status: event.target.value ? "Yes" : "No",
                          id: params.value.id,
                        },
                      };
                    } else {
                      return { ...e };
                    }
                  });
                });
              }}
            />
          </div>
        );
      },
      sortComparator: (v1, v2) => v1.status.localeCompare(v2.status),
    },
    {
      field: "marquee",
      headerName: "Marquee",
      flex: 1,
      renderCell: (params) => {
        let temp = [{ option: "Yes" }, { option: "No" }];
        return (
          <div style={{ float: "right" }}>
            <SelectLabels
              disabled
              selectBg="transparent"
              slist={temp}
              active={params.value.status}
              setActive={(event) => {
                setBiddingPlayerRows((prev) => {
                  return prev.map((e) => {
                    if (e.id === params.value.id) {
                      dispatch();
                      // editBiddingPlayer({
                      //   _id: params.value.id,
                      //   status:
                      //     event.target.value === "Enabled" ? true : false,
                      // })
                      return {
                        ...e,
                        Status: {
                          status: event.target.value ? "Enabled" : "Disabled",
                          id: params.value.id,
                        },
                      };
                    } else {
                      return { ...e };
                    }
                  });
                });
              }}
            />
          </div>
        );
      },
      sortComparator: (v1, v2) => v1.status.localeCompare(v2.status),
    },
    {
      field: "bowlingHanded",
      headerName: "Bowling",
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                fontFamily: "Poppins-Medium",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div>{params.value}</div>
            </div>
          </>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "battingHanded",
      headerName: "Batting",
      flex: 2,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                fontFamily: "Poppins-Medium",
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div>{params.value}</div>
            </div>
          </>
        );
      },
      sortComparator: (v1, v2) => v1.localeCompare(v2),
    },
    {
      field: "delete",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 1,
      minWidth: 120,
      renderHeader: () => <></>,
      renderCell: (params) => {
        return (
          <Cbtn
            onClick={async () => {
              await dispatch(deleteBiddingPlayer(params.value));
              window.location.reload();
            }}
            className={BiddingPlayersStyle.Cbtn}
            style={{
              backgroundColor: "#FFFFFF",
              width: "100% !important",
              padding: "0.7rem 2.5rem",
            }}
          >
            <Deleteb
              style={{
                marginRight: "8px",
                marginBottom: "5px",
              }}
            ></Deleteb>
          </Cbtn>
        );
      },
    },
    // {
    //   field: "Assign",
    //   headerName: "Assign",
    //   flex: 1,
    //   maxWidth: 90,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <Cbtn
    //           onClick={() => {
    //             setAssignModel((prev) => ({
    //               ...prev,
    //               [params.value.id]: true,
    //             }));
    //           }}
    //           style={{
    //             backgroundColor: "transparent",
    //             width: "100% !important",
    //             // padding: "0.7rem 2.5rem",
    //             padding: "0",
    //             color: "#0040A8",
    //             fontFamily: "Poppins-SemiBold",
    //             // fontSize: "0.6rem",
    //             letterSpacing: "0px",
    //           }}
    //         >
    //           Assign Work
    //         </Cbtn>
    //         {assignModel[params.value.id] && (
    //           <Assignbundle
    //             biddingPlayerId={params.value.id}
    //             paymentAmount={params.value.balance}
    //             show={assignModel[params.value.id]}
    //             setShow={(value) => {
    //               setAssignModel((prev) => ({
    //                 ...prev,
    //                 [params.value.id]: value,
    //               }));
    //             }}
    //           ></Assignbundle>
    //         )}
    //       </>
    //     );
    //   },
    // },
    // {
    //   field: "Id",
    //   headerName: "Id",
    //   sortable: false,
    //   flex: 1,
    //   maxWidth: 80,
    //   renderCell: (params) => {
    //     return (
    //       <Cbtn
    //         onClick={() => {
    //           alert(`username is : ${params.value}`);
    //         }}
    //         style={{
    //           backgroundColor: "transparent",
    //           width: "100% !important",
    //           color: "#0040A8",
    //           fontFamily: "Poppins-SemiBold",
    //           fontSize: "0.9rem",
    //           letterSpacing: "0px",
    //         }}
    //       >
    //         View
    //       </Cbtn>
    //     );
    //   },
    // },
    // {
    //   field: "PostCount",
    //   headerName: "Post",
    //   sortable: true,
    //   flex: 1,
    //   maxWidth: 70,
    // },
    // {
    //   field: "balancepayable",
    //   headerName: "Balance Payable",
    //   sortable: false,
    //   flex: 1,
    //   minWidth: 180,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <div>{params.value.balance}</div>
    //         {/* <Cbtn
    //           onClick={() => {
    //             setAssignModel((prev) => ({
    //               ...prev,
    //               [params.value.id]: true,
    //             }));
    //           }}
    //           style={{
    //             backgroundColor: "transparent",
    //             width: "100% !important",
    //             // padding: "0.7rem 2.5rem",
    //             color: "#0040A8",
    //             fontFamily: "Poppins-SemiBold",
    //             fontSize: "0.9rem",
    //             letterSpacing: "0px",
    //           }}
    //         >
    //           Assign Work
    //         </Cbtn> */}
    //         {balanceLoader[params.value.index] && (
    //           <CircularProgress
    //             sx={{ m: " 0 0.5rem", h: "0.7rem", w: "0.7rem" }}
    //           />
    //         )}
    //         <Cbtn
    //           onClick={async () => {
    //             setBalanceLoader((prev) =>
    //               prev.map((ele, i) =>
    //                 i === params.value.index ? true : false
    //               )
    //             );
    //             setBalanceLoader((prev) => prev.map((ele, i) => false));
    //           }}
    //           style={{
    //             backgroundColor: "transparent",
    //             width: "100% !important",
    //             color: "#0040A8",
    //             fontFamily: "Poppins-SemiBold",
    //             fontSize: "0.9rem",
    //             letterSpacing: "0px",
    //           }}
    //         >
    //           View
    //         </Cbtn>
    //         {/* {assignModel[params.value.id] && (
    //           <Assignbundle
    //             biddingPlayerId={params.value.id}
    //             paymentAmount={params.value.balance}
    //             show={assignModel[params.value.id]}
    //             setShow={(value) => {
    //               setAssignModel((prev) => ({
    //                 ...prev,
    //                 [params.value.id]: value,
    //               }));
    //             }}
    //           ></Assignbundle>
    //         )} */}
    //       </>
    //     );
    //   },
    // },
  ];

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Container>
            <Row className={BiddingPlayersStyle.container}>
              <Col md={{ span: 4 }}>
                <h1 className={BiddingPlayersStyle.mainHeading}>Players</h1>
              </Col>
              <Col style={{ display: "flex", justifyContent: "right" }}>
                <Cbtn
                  className={BiddingPlayersStyle.resetBtn}
                  onClick={async () => {
                    if (
                      await window.confirm(
                        "Are You Sure? This Will Reset Your Teams And Players."
                      )
                    ) {
                      let res = await dispatch(fetchResetData());
                      if (res.payload.statusCode === 201) {
                        alert("Data Resetted/Updated");
                        window.location.reload();
                      } else {
                        alert("Some Error Occured! Retry");
                      }
                    }
                  }}
                  style={{
                    marginTop: "30px",
                    // float: "right",
                    backgroundColor: "rgb(60 67 78)",
                    fontFamily: "Poppins-Medium",
                  }}
                >
                  <img
                    src={refresh}
                    alt=""
                    style={{
                      height: "1rem",
                      marginRight: "4px",
                    }}
                  />
                  Reset/Fetch Data
                </Cbtn>
                <Cbtn
                  to="/biddingPlayers/new"
                  style={{
                    marginLeft: "1rem",
                    marginTop: "30px",
                    // float: "right",
                    backgroundColor: "#0040A8",
                    fontFamily: "Poppins-Medium",
                  }}
                >
                  <Add className={`${BiddingPlayersStyle.add} `} />
                  Add Player Listing
                </Cbtn>
              </Col>
            </Row>
            <Row style={{ padding: "0rem 2.5rem 1rem", width: "100%" }}>
              <Col md={{ span: 3 }} style={{ marginTop: "10px" }}>
                <SearchBar
                  value={query}
                  onChange={handleSearch}
                  sx={{
                    borderRadius: "8px",
                  }}
                  searchSx={{
                    padding: "12px",
                  }}
                  searchColor="#b2acbd"
                  placeholder="Search Here... "
                />
              </Col>
            </Row>
            <Row
              style={{ padding: "1rem 3.5rem", width: "100%", height: "33rem" }}
              className={`${BiddingPlayersStyle.table}`}
            >
              <DataGrid
                autoWidth
                autoHeight
                rowHeight={60}
                disableSelectionOnClick
                onSelectionModelChange={(ids) => {
                  setDeleteIds(ids);
                }}
                disableColumnFilter
                disableColumnMenu
                headerHeight={70}
                pageSize={20}
                rowsPerPageOptions={[20]}
                sx={{
                  maxHeight: "60vh",
                  borderRadius: "10px",
                  border: "none",
                  width: "calc(inherit - 100px)",
                  padding: 0,
                  boxShadow: "1px 1px 6px 1px #eaeaed",

                  "& .MuiDataGrid-main": {
                    borderRadius: "10px",
                    maxHeight: "70vh",
                    overflowY: "overlay",
                  },
                  "& .MuiDataGrid-row": {
                    background: "#fff !important",
                  },
                  "& .MuiCheckbox-root": {
                    color: " #DBDBDB!important",
                  },
                  "& .MuiCheckbox-root.Mui-checked": {
                    color: "#0040A8 !important",
                  },
                  '& div[data-rowIndex][role="row"]': {
                    fontSize: 14,
                    fontFamily: "Poppins-Medium",
                    "& div": {
                      paddingTop: "2rem ",
                      paddingBottom: "2rem ",
                      fontFamily: "Poppins-Medium",
                    },
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    overflow: "inherit",
                  },
                  "& 	.MuiDataGrid-cellCheckbox": {
                    width: "4rem !important",
                    maxWidth: "4rem !important",
                    minWidth: "4rem !important",
                    marginRight: "-15px !important",
                  },

                  "& .MuiDataGrid-columnHeaders": {
                    width: "100% !important",
                    "& .MuiDataGrid-columnHeadersInner": {
                      backgroundColor: "#fcfcfc",

                      "& .MuiDataGrid-columnSeparator": {
                        display: "none",
                      },
                      "& .MuiDataGrid-columnHeaderTitle": {
                        fontFamily: "Poppins-SemiBold",
                        color: "#363B64",
                        fontSize: "14px",
                      },
                    },
                  },
                  "& .MuiDataGrid-cellContent": {
                    fontFamily: "Poppins-Regular",
                    color: "#363B64",
                    background: "#fff",

                    // fontStyle: "1rem",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    fontFamily: "Poppins-Medium",
                    borderRadius: "0px 0px 10px 10px",
                    background: "#fff !important",
                    minHeight: "35px",
                    "& .css-levciy-MuiTablePagination-displayedRows": {
                      margin: "1rem",
                    },
                  },
                  "& .MuiDataGrid-columnHeaderCheckbox, .css-1yoodjx-MuiDataGrid-root .MuiDataGrid-cellCheckbox":
                    {
                      width: "4rem !important",
                      maxWidth: "4rem !important",
                      minWidth: "4rem !important",
                      marginRight: "-15px !important",
                    },
                }}
                rows={biddingPlayerRows}
                columns={columns}
                loading={loading}
              />
              <Deletefilemodal
                ids={deleteIds}
                deleteFn={() => {}}
                show={showDel}
                setShow={setShowDel}
              />
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}
