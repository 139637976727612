import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import Popover from "@mui/material/Popover";
import { Stepper, Typography } from "@mui/material";

import { Link, useNavigate } from "react-router-dom";

//component import
import Cbtn from "../commonbutton/cbtn";

//scss
import TopNavStyle from "./topNav.module.scss";

//svg import
import { ReactComponent as Message } from "../../resources/svg/announcement.svg";
import { ReactComponent as Announcement } from "../../resources/svg/msg.svg";
import AdminProfile from "../profile/adminProfile";

//bootstrap import
import { Row, Col, Container } from "react-bootstrap";

import Steppers from "../createJob/createJob";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getSession, selectSession } from "../../store/features/adminSlice";

function createData(
  _id,
  bundleCode,
  bundleNumber,
  primaryColorCode,
  shadeCode,
  Piece,
  faultyStep,
  size,
  button
) {
  return {
    _id,
    bundleCode,
    bundleNumber,
    primaryColorCode,
    shadeCode,
    Piece,
    faultyStep,
    size,
    button,
  };
}

// Component
export default function TopNavBar({ isMdUp, isSmUp }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [show, setShow] = useState(false);
  const [acRow, setAcRow] = useState([
    createData(
      1,
      "100/001/C-1/30/XL",
      "100",
      "001#FF0000",
      "C-1#464646",
      "30",
      "180",
      "XL",
      "Re-Enable"
    ),
  ]);

  // let getSessionData = async () => {
  //   let res = await dispatch(getSession());
  //   return res;
  // };
  let session = useSelector(selectSession);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  let navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeFaultyStatus = (_id, code, step) => async () => {
    // let res = await dispatch(
    //   changeFaultyBundle({ _id, status: "false", code, step })
    // );
    // if (res.payload.statusCode === 200) {
    window.location.reload();
    // }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Container>
      <Row
        style={{
          alignItems: "right",
          width: "100%",
          justifyContent: "end",
          marginTop: isMdUp ? "10px" : "20px",
        }}
      >
        {session?._id && (
          <Col
            xs={{ span: 1, order: 1 }}
            md={{ span: 1, order: 2 }}
            lg={{ span: 1, offset: 3, order: 2 }}
            xl={{ span: 1, offset: 3, order: 2 }}
            style={{ width: "fit-content", marginRight: "1rem" }}
          >
            <Row>
              <Cbtn
                newTab={true}
                className={TopNavStyle.AuctionPageBtn}
                link={
                  "https://auction.spectors.in/auction/" + session?.sessionId
                }
                style={{
                  fontWeight: "bold",
                  borderRadius: "7px",
                  fontSize: "0.8rem",
                  height: "2.5rem",
                  backgroundColor: "#f5f5f5",

                  fontFamily: "Poppins-Regular",
                }}
              >
                Auction Page
              </Cbtn>
            </Row>
          </Col>
        )}

        <Col style={{ width: "fit-content" }} lg={{ span: 1, order: 2 }}>
          <Row>
            <Cbtn
              newTab={true}
              className="huehuehue"
              link="https://auction.spectors.in/curator"
              style={{
                borderRadius: "7px",
                fontSize: "0.8rem",
                height: "2.5rem",
                backgroundColor: "#0040A8",
                color: "#f5f5f5",
                fontFamily: "Poppins-Regular",
              }}
            >
              Curator Portal
            </Cbtn>
            {/* <Steppers show={show} setShow={setShow} /> */}
          </Row>
        </Col>
        <Col
          xs={{ span: 1, order: 2 }}
          md={{ span: 1, order: 3 }}
          lg={{ span: 1, order: 3 }}
          style={{ width: "fit-content" }}
        >
          <Popover
            id={id}
            open={open}
            disabled
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            onClose={handleClose}
            PaperProps={{
              sx: {
                borderRadius: "12px",
                boxShadow:
                  "0px 5px 5px -3px rgb(0 0 0 / 5%), 0px 8px 10px 1px rgb(0 0 0 / 5%), 0px 3px 14px 2px rgb(0 0 0 / 5%)",
              },
            }}
          >
            <Typography
              sx={{ p: 2, fontFamily: "Poppins-Regular", width: "350px" }}
            >
              <Row>
                <Typography
                  sx={{ p: 1, fontFamily: "Poppins-SemiBold", fontSize: 14 }}
                >
                  Reported Bundle
                </Typography>
              </Row>
              {acRow.map((row, i) => (
                <Row key={i} style={{ marginBottom: "10px" }}>
                  <Col sm="8">
                    <Typography
                      sx={{ fontFamily: "Poppins-Regular", fontSize: 13 }}
                    >
                      {row.bundleCode}
                    </Typography>
                  </Col>
                  <Col>
                    <Cbtn
                      style={{
                        backgroundColor: "#0040A8",
                        fontSize: "10px",
                        padding: "0.5rem 0.5rem",
                        float: "right",
                      }}
                      onClick={changeFaultyStatus(
                        row._id,
                        row.bundleCode,
                        row.faultyStep
                      )}
                    >
                      Re-Enable
                    </Cbtn>
                  </Col>
                </Row>
              ))}

              <Row style={{ padding: "0.8rem" }}>
                <Col>
                  <Link to="/#action">
                    <button
                      style={{
                        fontFamily: "Poppins-SemiBold",
                        color: "#0040A8",
                        fontSize: "11.5px",
                        border: "none",
                        background: "transparent",
                        textDecoration: "underline",
                        float: "right",
                      }}
                    >
                      View All
                    </button>
                  </Link>
                </Col>
              </Row>
            </Typography>
          </Popover>
          {/* <IconButton
            aria-label="show 4 new mails"
            color="inherit"
            sx={{ marginLeft: "1.8rem" }}
          >
            <Link to="/chatroom">
              <Badge badgeContent={0} color="error">
                <Message style={{ height: "1.5rem", width: "1.5rem" }} />
              </Badge>
            </Link>
          </IconButton>
          <IconButton
            size=""
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            className={TopNavStyle.announcementDiv}
          >
            <Badge badgeContent={0} color="error">
              <Announcement
                className={TopNavStyle.announcement}
                style={{ height: "1.5rem", width: "1.5rem" }}
              />
            </Badge>
          </IconButton> */}
        </Col>
        <Col
          xs={{ span: 4, order: 3 }}
          md={{ span: 3, order: 4 }}
          lg={{ span: 1, order: 4 }}
        >
          <AdminProfile />
        </Col>
      </Row>
    </Container>
  );
}
