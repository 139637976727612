import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import franchiseRoutes from "../network routes/franchise";

export const getFranchise = createAsyncThunk(
  "franchise/getFranchise",
  async () => {
    const response = await franchiseRoutes.getFranchises();
    return response;
  }
);

export const getFreeFranchise = createAsyncThunk(
  "franchise/getFreeFranchise",
  async () => {
    const response = await franchiseRoutes.getFreeFranchises();
    return response;
  }
);

export const getFranchiseById = createAsyncThunk(
  "franchise/getFranchiseById",
  async (id) => {
    const response = await franchiseRoutes.getFranchisesById(id);
    return response;
  }
);

export const createFranchise = createAsyncThunk(
  "franchise/createFranchise",
  async (data) => {
    const response = await franchiseRoutes.createFranchise(data);
    return response;
  }
);

export const editFranchise = createAsyncThunk(
  "franchise/editFranchise",
  async (data) => {
    const response = await franchiseRoutes.editFranchise(data);
    return response;
  }
);

export const deleteFranchise = createAsyncThunk(
  "franchise/deleteFranchise",
  async (id) => {
    const response = await franchiseRoutes.deleteFranchise(id);
    return response;
  }
);

export const assignOwner = createAsyncThunk(
  "franchise/assignOwner",
  async (data) => {
    const response = await franchiseRoutes.assignOwner(data);
    return response;
  }
);

export const removeOwner = createAsyncThunk(
  "franchise/removeOwner",
  async (id) => {
    const response = await franchiseRoutes.removeOwner(id);
    return response;
  }
);

const franchiseSlice = createSlice({
  name: "franchise",
  initialState: {
    data: [],
    freeFranchise: [],
    dataById: {},
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getFranchise.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data[0]) {
        state.data = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getFranchise.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getFreeFranchise.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data[0]) {
        state.freeFranchise = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getFreeFranchise.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getFranchiseById.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.dataById = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [getFranchiseById.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createFranchise.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.data.push(action.payload.data);
      } else {
        state.error = "Login first";
      }
    },
    [createFranchise.rejected]: (state, action) => {
      state.error = action.error;
    },
    [editFranchise.fulfilled]: (state, action) => {
      // // console.log(action);
      if (action.payload.statusCode === 200) {
        let index = state.data.findIndex(
          (ele) => ele._id === action.payload.data._id
        );
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [editFranchise.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteFranchise.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        if (state.data) {
          //   let index = state.data.findIndex(
          //     (ele) => ele._id === action.payload.data._id
          //   );
          //   state.data[index] = action.payload.data;
        } else {
          state.error = "Login first";
        }
      }
    },
    [deleteFranchise.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const selectfranchiseData = (state) => state.franchise.data;
export const selectFreeFranchiseData = (state) => state.franchise.freeFranchise;
export const selectFranchiseError = (state) => state.franchise.error;
export const selectFranchiseDataById = (state) => state.franchise.dataById;
export default franchiseSlice.reducer;
