import React, { useEffect } from "react";

//scss
import EditBidderStyle from "./editBidder.module.scss";

//bootstrap
import { Form, Container, Row, Col } from "react-bootstrap";

//svg
import { ReactComponent as Save } from "../../resources/svg/save.svg";

//component
import SelectLabels from "../dropdowns/1/dropdown1";
import ResponsiveDrawer from "../responsiveDrawer/responsiveDrawer";
import Snackbar from "../snackbar/snackbar";
import { Typography } from "@mui/material";
import Cbtn from "../commonbutton/cbtn";

//redux
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectStatus } from "../../store/features/adminSlice";

import LoadingScreen from "../loadingSpinner/loadingScreen";

import axios from "axios";
import { useParams, useNavigate, Link, Navigate } from "react-router-dom";
import instance from "../../store/network routes/instance";
import {
  assignFranchise,
  editBidder,
  getBidderById,
  removeFranchise,
  selectBidderDataById,
} from "../../store/features/teamOwnerSlice";
import {
  getFreeFranchise,
  selectFreeFranchiseData,
} from "../../store/features/franchiseSlice";

const EditBidder = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [franchiseOptions, setFranchiseOptions] = useState([]);
  const [editBidderDetail, setEditBidderDetail] = useState({
    name: "",
    sex: "",
    post: "",
    id: "",
    identity: "",
    password: "",
    active: "",
    active1: false,
    profilePic: "",
  });
  const [showError, setShowError] = React.useState(false);
  const [error, setError] = React.useState("");
  const [disableSubmit, setDisableSubmit] = React.useState("");

  const dispatch = useDispatch();

  const loadingStatus = useSelector(selectStatus);
  const bidder = useSelector(selectBidderDataById);
  let franchises = useSelector(selectFreeFranchiseData);

  const sendFile = async (file) => {
    let bodyFormData = new FormData();
    setDisableSubmit(true);
    bodyFormData.append("file", file);
    try {
      if (bodyFormData.get("file")) {
        let res = await instance({
          method: "post",
          url: "uploadFile",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        });
        if (res.data.statusCode === 200) {
          setDisableSubmit(false);
          return res.data.url;
        }
      }
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        alert("File size should be less than 50mb");
        setDisableSubmit(false);
        document.getElementById("IDProof").value = "";
        setEditBidderDetail((prev) => ({
          ...prev,
          // identity: bidder?.identity,
        }));
      }
    }
  };

  React.useEffect(() => {
    if (id) {
      dispatch(getBidderById(id));
    }
  }, [id]);

  React.useEffect(() => {
    if (bidder) {
      // console.log(bidder);
      setEditBidderDetail({
        ...bidder,
      });
    }
    // }, []);
  }, [bidder]);

  const handleNumberFormChange = (e) => {
    let value = Number(e.target.value);
    // console.log(value);
    if (value > 0)
      setEditBidderDetail({
        ...editBidderDetail,
        [e.target.name]: value || e.target.value,
      });
  };

  const handleFormChange = (e, value) => {
    setEditBidderDetail({
      ...editBidderDetail,
      [e.target.name]: value || e.target.value,
    });
  };

  useEffect(() => {
    if (franchises[0]) {
      setFranchiseOptions(() =>
        franchises.map((team) => ({ option: team.name }))
      );
    }
  }, [franchises]);

  useEffect(() => {
    dispatch(getFreeFranchise());
  }, []);

  const handleChange = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditBidderDetail({
        ...editBidderDetail,
        profilePic: res,
      });
    }
  };

  const handleChange1 = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditBidderDetail({
        ...editBidderDetail,
        identity: res,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { password, userId, name, email, purse, _id } = editBidderDetail;

    const validate = Object.values({
      name,
      email,
      userId,
      _id,
    }).every(Boolean);

    if (validate) {
      submitForm({
        _id,
        name,
        purse,
        userId,
        email,
        password,
      });
    }
  };

  const submitForm = async (data) => {
    const res = await dispatch(editBidder(data));
    if (res) {
      setEditBidderDetail({
        name: "",
        email: "",
        franchise: "",
        userId: "",
        purse: 0,
        password: "",
      });
      navigate("/bidders");
    }
    return res;
  };

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showError}
            setShow={setShowError}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          ></Snackbar>
          <Container className={EditBidderStyle.container}>
            <Row className={EditBidderStyle.Container}>
              <Col>
                <h1 className={EditBidderStyle.mainHeading}>Edit Bidders</h1>
              </Col>
            </Row>
            <Row className={EditBidderStyle.formContainer}>
              <Form onSubmit={handleSubmit}>
                {/* <Row>
                  <Col>
                    <input
                      type="file"
                      className={EditBidderStyle.customFileInput}
                      accept="image/*"
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        background: editBidderDetail.image
                          ? `url(${editBidderDetail.image}) #CBCBCB center center/cover`
                          : "#CBCBCB",
                        height: "110px",
                        width: "110px",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "-60px",
                        right: "70px",
                        zIndex: 2,
                      }}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Form.Group as={Col} className="mb-4" controlId="Name">
                    <Form.Label className={EditBidderStyle.label}>
                      Name
                    </Form.Label>
                    <Form.Control
                      className={EditBidderStyle.control}
                      type="name"
                      name="name"
                      onChange={handleFormChange}
                      placeholder="name"
                      value={editBidderDetail.name}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="Franchise">
                    <Form.Label className={EditBidderStyle.label}>
                      Franchise
                      {editBidderDetail?.Franchise?.name && (
                        <Cbtn
                          className={EditBidderStyle.Cbtn}
                          onClick={async () => {
                            let res = await dispatch(removeFranchise(id));
                            if (res) {
                              await dispatch(getFreeFranchise());
                              await setEditBidderDetail((prev) => ({
                                ...prev,
                                Franchise: null,
                              }));
                              window.location.reload();
                            }
                          }}
                        >
                          Remove Franchise?
                        </Cbtn>
                      )}
                    </Form.Label>
                    <SelectLabels
                      name="Franchise"
                      disabled={
                        editBidderDetail?.Franchise?.name ? true : false
                      }
                      slist={franchiseOptions}
                      active={editBidderDetail?.Franchise?.name}
                      setActive={async (e) => {
                        let Franchise = await franchises?.find(
                          (team) => team.name === e.target.value
                        );
                        if (Franchise) {
                          await dispatch(
                            assignFranchise({
                              _id: editBidderDetail._id,
                              franchiseId: Franchise._id,
                            })
                          );
                          await setEditBidderDetail((prev) => ({
                            ...prev,
                            Franchise,
                          }));
                        }
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-4" controlId="Email">
                    <Form.Label className={EditBidderStyle.label}>
                      Email
                    </Form.Label>

                    <Form.Control
                      type="email"
                      placeholder="John Doe"
                      name="email"
                      className={EditBidderStyle.control}
                      value={editBidderDetail.email}
                      onChange={handleFormChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="ID">
                    <Form.Label className={EditBidderStyle.label}>
                      Username
                    </Form.Label>
                    <Form.Control
                      // id={""}
                      className={EditBidderStyle.control}
                      type="text"
                      name="userId"
                      placeholder="username"
                      onChange={handleFormChange}
                      value={editBidderDetail.userId}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-4" controlId="IDProof">
                    <Form.Label className={EditBidderStyle.label}>
                      Purse(In L)
                    </Form.Label>
                    <Form.Control
                      className={EditBidderStyle.control}
                      type="number"
                      min={0}
                      name="purse"
                      onChange={handleNumberFormChange}
                      value={editBidderDetail.purse}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="Password">
                    <Form.Label className={EditBidderStyle.label}>
                      Password
                    </Form.Label>
                    <Form.Control
                      className={EditBidderStyle.control}
                      type="password"
                      name="password"
                      onChange={handleFormChange}
                      value={editBidderDetail.Password}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col xl={6}>
                    <Cbtn
                      disabled={disableSubmit}
                      type="submit"
                      style={{
                        fontSize: "0.8rem",
                        backgroundColor: disableSubmit ? "grey" : "#0040A8",
                        color: disableSubmit ? "black" : "#fff",
                        margin: "auto",
                        float: "right",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                        padding: "11px 34px",
                      }}
                    >
                      <Save
                        style={{ marginRight: "5px" }}
                        className={EditBidderStyle.save}
                      />
                      Save
                    </Cbtn>
                  </Col>
                  <Col xl={6}>
                    <Cbtn
                      to="/bidders"
                      disabled={disableSubmit}
                      type="button"
                      style={{
                        backgroundColor: "rgb(57 42 42)",
                        color: "white",
                        margin: "auto",
                        fontSize: "0.8rem",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                      }}
                    >
                      Discard
                    </Cbtn>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
};

export default EditBidder;
