import React, { useEffect, useState } from "react";
import axios from "axios";
//scss
import AddBidderStyle from "./addBidder.module.scss";

//bootstrap
import { Form, Container, Row, Col } from "react-bootstrap";

//svg
import { ReactComponent as Save } from "../../resources/svg/save.svg";

//component
import ResponsiveDrawer from "../../components/responsiveDrawer/responsiveDrawer";
import Cbtn from "../../components/commonbutton/cbtn";
import SelectLabels from "../../components/dropdowns/1/dropdown1";
import Autocomplete from "../../components/autoComplete/autoComplete";
import Snackbar from "../../components/snackbar/snackbar";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import CancelIcon from "@mui/icons-material/Cancel";
import instance from "../../store/network routes/instance";
import { selectStatus } from "../../store/features/adminSlice";
import {
  getFranchise,
  getFreeFranchise,
  selectfranchiseData,
  selectFreeFranchiseData,
} from "../../store/features/franchiseSlice";
import { createBidder } from "../../store/features/teamOwnerSlice";

function AddBidder() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [franchiseOptions, setFranchiseOptions] = useState([]);
  let franchises = useSelector(selectFreeFranchiseData);

  const sendFile = async (file) => {
    let bodyFormData = new FormData();
    setDisableSubmit(true);
    bodyFormData.append("file", file);
    try {
      if (bodyFormData.get("file")) {
        let res = await instance({
          method: "email",
          url: "uploadFile",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        });
        if (res.data.statusCode === 200) {
          setDisableSubmit(false);
          return res.data.url;
        } else {
        }
      }
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        alert("File size should be less than 50mb");
        setDisableSubmit(false);
        document.getElementById("formBasicIDProof").value = "";
        setBidderDetail((prev) => ({
          ...prev,
          formBasicIDProof: "",
        }));
      }
    }
  };

  const [bidderDetail, setBidderDetail] = useState({
    formBasicName: "",
    formBasicFranchise: { name: "", id: null },
    formBasicEmail: "",
    formBasicID: "",
    formBasicIDProof: "",
    formBasicPassword: "",
    formPhoto: "",
  });
  const [error, setError] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const handleChange = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setBidderDetail({
        ...bidderDetail,
        formPhoto: res,
      });
    }
  };

  const handleChange1 = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setBidderDetail({
        ...bidderDetail,
        formBasicIDProof: res,
      });
    }
  };

  const handleFormChange = (e, value) => {
    setBidderDetail({
      ...bidderDetail,
      [e.target.name]: value || e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("reuyvb");
    let validate = false;
    let bidderObj = {
      name: "",
      Franchise: null,
      email: "",
      userId: "",
      password: "",
      profilePic: "",
    };

    if (bidderDetail.formBasicName) {
      bidderObj.name = bidderDetail.formBasicName;
      if (bidderDetail.formBasicEmail) {
        bidderObj.email = bidderDetail.formBasicEmail;
        if (bidderDetail.formBasicID) {
          bidderObj.userId = bidderDetail.formBasicID;
          if (bidderDetail.formBasicPassword) {
            bidderObj.password = bidderDetail.formBasicPassword;
            validate = true;
            bidderObj.Franchise = bidderDetail.formBasicFranchise.id || null;
            bidderObj.profilePic = bidderDetail.formPhoto;
          }
        }
      }
    }

    if (validate) {
      submitForm(bidderObj);
    } else {
      setError("Enter Full Details");
      setShowError(true);
      validate = false;
    }
  };

  useEffect(() => {
    dispatch(getFreeFranchise());
  }, []);

  useEffect(() => {
    if (franchises[0]) {
      setFranchiseOptions(() =>
        franchises.map((team) => ({ option: team.name }))
      );
    }
  }, [franchises]);

  const submitForm = async (data) => {
    const res = await dispatch(createBidder(data));
    console.log(res);
    if (res.payload.statusCode === 201) {
      setBidderDetail({
        formBasicName: "",
        formBasicFranchise: "",
        formBasicEmail: "",
        formBasicID: "",
        formBasicIDProof: "",
        formBasicPassword: "",
        formPhoto: "",
      });
      navigate("/bidders");
    } else if (res.payload.statusCode === 400) {
      setError(res.payload.msg);
      setShowError(true);
    }
    return res;
  };

  const loadingStatus = useSelector(selectStatus);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showError}
            setShow={setShowError}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          ></Snackbar>
          <Container className={AddBidderStyle.container}>
            <Row className={AddBidderStyle.Container}>
              <Col>
                <h1 className={AddBidderStyle.mainHeading}>Add Bidders</h1>
              </Col>
            </Row>
            <Row
              className={`${AddBidderStyle.Container} ${AddBidderStyle.formContainer}`}
            >
              <Form onSubmit={handleSubmit}>
                {/* <Row>
                  <Col>
                    <input
                      type="file"
                      className={AddBidderStyle.customFileInput}
                      accept="image/*"
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        background: bidderDetail.formPhoto
                          ? `url(${bidderDetail.formPhoto}) #CBCBCB center center/cover`
                          : "#CBCBCB",
                        height: "110px",
                        width: "110px",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "-60px",
                        right: "70px",
                        zIndex: 2,
                      }}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Form.Group
                    as={Col}
                    className="mb-4"
                    controlId="formBasicName"
                  >
                    <Form.Label className={AddBidderStyle.label}>
                      Name
                    </Form.Label>
                    <Form.Control
                      className={AddBidderStyle.control}
                      type="name"
                      name="formBasicName"
                      onChange={handleFormChange}
                      placeholder="name"
                      value={bidderDetail.formBasicName}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    className="mb-4"
                    controlId="formBasicFranchise"
                  >
                    <Form.Label className={AddBidderStyle.label}>
                      Franchise
                    </Form.Label>
                    <SelectLabels
                      name="formBasicFranchise"
                      slist={franchiseOptions}
                      active={bidderDetail?.formBasicFranchise?.name}
                      setActive={(e) => {
                        console.log(franchises);
                        let franchise = franchises.find((team) => {
                          return team.name === e.target.value;
                        });
                        console.log(franchise, e.target.value);
                        setBidderDetail({
                          ...bidderDetail,
                          formBasicFranchise: {
                            name: e.target.value,
                            id: franchise._id,
                          },
                        });
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    className="mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className={AddBidderStyle.label}>
                      Email
                    </Form.Label>

                    <Form.Control
                      type="email"
                      placeholder="John Doe"
                      name="formBasicEmail"
                      className={AddBidderStyle.control}
                      value={bidderDetail.formBasicEmail}
                      onChange={handleFormChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="formBasicID">
                    <Form.Label className={AddBidderStyle.label}>
                      Username
                    </Form.Label>
                    <Form.Control
                      // id={""}
                      className={AddBidderStyle.control}
                      type="text"
                      name="formBasicID"
                      placeholder="username"
                      onChange={handleFormChange}
                      // value={bidderDetail.formBasicID}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    className="mb-4"
                    controlId="formBasicPassword"
                  >
                    <Form.Label className={AddBidderStyle.label}>
                      Password
                    </Form.Label>
                    <Form.Control
                      className={AddBidderStyle.control}
                      type="password"
                      name="formBasicPassword"
                      onChange={handleFormChange}
                      value={bidderDetail.formBasicPassword}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Col xl={6}>
                    <Cbtn
                      disabled={disableSubmit}
                      type="submit"
                      style={{
                        fontSize: "0.8rem",
                        backgroundColor: disableSubmit ? "grey" : "#0040A8",
                        color: disableSubmit ? "black" : "#fff",
                        margin: "auto",
                        float: "right",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                        padding: "11px 34px",
                      }}
                    >
                      <Save
                        style={{ marginRight: "5px" }}
                        className={AddBidderStyle.save}
                      />
                      Save
                    </Cbtn>
                  </Col>
                  <Col xl={6}>
                    <Cbtn
                      to="/bidders"
                      disabled={disableSubmit}
                      type="button"
                      style={{
                        backgroundColor: "rgb(57 42 42)",
                        color: "white",
                        margin: "auto",
                        fontSize: "0.8rem",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                      }}
                    >
                      Discard
                    </Cbtn>
                  </Col>
                </Row>
              </Form>
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}

export default AddBidder;
