import { combineReducers } from "redux";
import adminReducer from "./features/adminSlice";
import curatorReducer from "./features/curatorSlice";
import franchiseReducer from "./features/franchiseSlice";
import biddingPlayersReducer from "./features/biddingPlayerSlice";
import ownerReducer from "./features/teamOwnerSlice";

export default combineReducers({
  admin: adminReducer,
  bidder: ownerReducer,
  curator: curatorReducer,
  biddingPlayers: biddingPlayersReducer,
  franchise: franchiseReducer,
});
