import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import biddingPlayerRoutes from "../network routes/biddingPlayers";

export const getBiddingPlayers = createAsyncThunk(
  "biddingPlayer/getBiddingPlayers",
  async () => {
    const response = await biddingPlayerRoutes.getBiddingPlayers();
    return response;
  }
);

export const getBiddingPlayerById = createAsyncThunk(
  "biddingPlayer/getBiddingPlayerById",
  async (id) => {
    const response = await biddingPlayerRoutes.getBiddingPlayersById(id);
    return response;
  }
);

export const createBiddingPlayer = createAsyncThunk(
  "biddingPlayer/createBiddingPlayer",
  async (data) => {
    const response = await biddingPlayerRoutes.createBiddingPlayer(data);
    return response;
  }
);

export const editBiddingPlayer = createAsyncThunk(
  "biddingPlayer/editBiddingPlayer",
  async (data) => {
    const response = await biddingPlayerRoutes.editBiddingPlayer(data);
    return response;
  }
);

export const deleteBiddingPlayer = createAsyncThunk(
  "biddingPlayer/deleteBiddingPlayer",
  async (id) => {
    const response = await biddingPlayerRoutes.deleteBiddingPlayer(id);
    return response;
  }
);

export const assignBiddingPlayer = createAsyncThunk(
  "biddingPlayer/assignBiddingPlayer",
  async (data) => {
    const response = await biddingPlayerRoutes.assignBiddingPlayer(data);
    return response;
  }
);

export const fetchResetData = createAsyncThunk(
  "biddingPlayer/fetchResetData",
  async (data) => {
    const response = await biddingPlayerRoutes.fetchResetData(data);
    return response;
  }
);

export const getLastYearPlayers = createAsyncThunk(
  "biddingPlayer/getLastYearPlayers",
  async (id) => {
    const response = await biddingPlayerRoutes.getLastYearPlayers(id);
    return response;
  }
);

export const searchFreePlayers = createAsyncThunk(
  "biddingPlayer/searchBiddingPlayers",
  async ({ query, count }) => {
    const response = await biddingPlayerRoutes.searchFreePlayers({
      query,
      count,
    });
    return response;
  }
);

export const pushLastYearSoldPlayers = createAsyncThunk(
  "biddingPlayer/pushLastYearSoldPlayers",
  async ({ franchiseId, freePlayers }) => {
    const response = await biddingPlayerRoutes.pushLastYearSoldPlayers({
      franchiseId,
      freePlayers,
    });
    return response;
  }
);

const biddingPlayerSlice = createSlice({
  name: "biddingPlayers",
  initialState: {
    data: [],
    searchedPlayers: [],
    lastYearPlayers: [],
    dataById: {},
    error: "",
  },
  reducers: {},
  extraReducers: {
    [getBiddingPlayers.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data[0]) {
        state.data = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [getBiddingPlayers.rejected]: (state, action) => {
      state.error = action.error;
    },
    [searchFreePlayers.fulfilled]: (state, action) => {
      const { data, statusCode } = action.payload;
      if (statusCode === 200 && data[0]) {
        state.searchedPlayers = data;
      } else if (statusCode === 200) {
        state.error = "No Data!";
      } else {
        state.error = "Login first";
      }
    },
    [searchFreePlayers.rejected]: (state, action) => {
      state.error = action.error;
    },
    [pushLastYearSoldPlayers.fulfilled]: (state, action) => {
      console.log(action.payload);
      const { statusCode } = action.payload;
    },
    [pushLastYearSoldPlayers.rejected]: (state, action) => {
      state.error = action.error;
    },

    [getBiddingPlayerById.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.dataById = action.payload.data.data;
      } else {
        state.error = "Login first";
      }
    },
    [getBiddingPlayerById.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createBiddingPlayer.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.data.push(action.payload.data);
      } else {
        state.error = "Login first";
      }
    },
    [createBiddingPlayer.rejected]: (state, action) => {
      state.error = action.error;
    },
    [editBiddingPlayer.fulfilled]: (state, action) => {
      // // console.log(action);
      if (action.payload.statusCode === 200) {
        let index = state.data.findIndex(
          (ele) => ele._id === action.payload.data._id
        );
        state.data[index] = action.payload.data;
      } else {
        state.error = "Login first";
      }
    },
    [editBiddingPlayer.rejected]: (state, action) => {
      state.error = action.error;
    },
    [fetchResetData.fulfilled]: (state, action) => {
      const { statusCode } = action.payload;
      if (!statusCode === 200) {
        state.error = "Login first";
      }
    },
    [fetchResetData.rejected]: (state, action) => {
      state.error = action.error;
    },
    [getLastYearPlayers.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.lastYearPlayers = action.payload.data.data;
      } else {
        state.error = "Login first";
      }
    },
    [getLastYearPlayers.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteBiddingPlayer.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        if (state.data) {
          //   let index = state.data.findIndex(
          //     (ele) => ele._id === action.payload.data._id
          //   );
          //   state.data[index] = action.payload.data;
        } else {
          state.error = "Login first";
        }
      }
    },
    [deleteBiddingPlayer.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const selectbiddingPlayersData = (state) => state.biddingPlayers.data;
export const selectSearchFreePlayers = (state) =>
  state.biddingPlayers.searchedPlayers;
export const selectGetLastYearPlayers = (state) =>
  state.biddingPlayers.lastYearPlayers;
export const selectBiddingPlayersError = (state) => state.biddingPlayers.error;
export const selectBiddingPlayerDataById = (state) =>
  state.biddingPlayers.dataById;
export default biddingPlayerSlice.reducer;
