import React, { useState } from "react";
import { Row, Form } from "react-bootstrap";
import { CircularProgress, Input, Typography } from "@mui/material";
import Cbtn from "../commonbutton/cbtn";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { ReactComponent as Washing } from "../../resources/svg/washingMachine.svg";
import SelectLabels from "../dropdowns/1/dropdown1";
import { ReactComponent as Cross } from "../../resources/svg/X.svg";
import { ReactComponent as Add } from "../../resources/svg/Add.svg";
import Styles from "./steps.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useDrag } from "react-dnd";
import { ReactComponent as Delete } from "../../resources/svg/Trash.svg";

import LoadingScreen from "../loadingSpinner/loadingScreen";

function createData(stepCode, name, icon, id) {
  return {
    stepCode,
    name,
    icon,
    id,
  };
}

function DraggableTRows({ index, item, children }) {
  let [collected, drag] = useDrag(() => ({
    type: "step",
    index: index,
    item: { type: "step", index: index, data: item },
    collect: (monitor) => ({
      isdragging: String(monitor.isDragging()),
    }),
  }));

  return (
    <TableRow
      {...collected}
      ref={drag}
      key={index}
      sx={{
        cursor: "pointer",
        background: "#fff",
      }}
    >
      {children}
    </TableRow>
  );
}

function Steps({ createJob }) {
  const dispatch = useDispatch();
  const [state, setState] = useState([]);
  const [inputData, setInputData] = useState({
    name: "",
    stepCode: 0,
    icon: "",
  });

  const [inputError, setInputError] = useState({
    name: "",
    stepCode: "",
    icon: "",
  });

  const [showMod, setShowMod] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const SubmitForm = (e) => {
    e.preventDefault();
    let validate = false;
    if (inputData.name) {
      if (inputData.stepCode) {
        if (
          inputData.icon &&
          inputData.icon !== "Select" &&
          inputData.icon !== "select"
        ) {
          validate = true;
          setInputError({
            name: "",
            stepCode: "",
            icon: "",
          });
        } else {
          setInputError({
            ...inputError,
            icon: "Select Icons",
          });
        }
      } else {
        setInputError({
          ...inputError,
          stepCode: "Enter Code!",
        });
      }
    } else {
      setInputError({
        ...inputError,
        name: "Enter Name!",
      });
    }

    if (validate) {
      setShowLoader(true);
      submitData({
        name: inputData.name,
        stepCode: inputData.stepCode.toString(),
        icon: inputData.icon.props.src,
      });
    } else {
      alert("Verify Fields!");
    }
  };

  const submitData = async (data) => {
    // let res = await dispatch(postSteps(data));
    // await dispatch(getSteps(data));
    // if (res?.payload?.statusCode === 200) {
    setShowMod(false);
    setShowLoader(false);
    // window.location.reload();
    // }
    // return res;
  };

  let rows = state.map((item, index) => {
    return createData(item.stepCode, item.name, item.activeIcon, item.id);
  });

  // console.log(state);

  return (
    <>
      {showLoader && <CircularProgress />}
      {showMod && (
        <Form
          onSubmit={SubmitForm}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
            backgroundColor: "#80808082",
          }}
        >
          <Form.Group
            className={Styles.inputStep}
            style={{
              fontFamily: "Poppins-Regular",
              backgroundColor: "#fff",
              maxWidth: "16rem",
              position: "relative",
              left: "35%",
              top: "30%",
              padding: "50px 30px ",
              borderRadius: "12px",
            }}
          >
            <Cross
              className={Styles.cross}
              onClick={() => {
                setShowMod(false);
              }}
            />

            <Form.Label className={Styles.label} controlId="">
              Step Code
            </Form.Label>
            <Form.Control
              type="number"
              value={inputData.stepCode}
              className={Styles.input}
              min={0}
              onChange={(e) => {
                let value = Number(e.target.value);
                if (value > 0)
                  setInputData({
                    ...inputData,
                    stepCode: e.target.value,
                  });
              }}
            ></Form.Control>
            {inputError.stepCode && (
              <>
                <div>{inputError.stepCode}</div>
              </>
            )}
            <Form.Label className={Styles.label} controlId="">
              Name
            </Form.Label>
            <Form.Control
              value={inputData.name}
              className={Styles.input}
              // disabled
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  name: e.target.value,
                });
              }}
            ></Form.Control>

            {inputError.name && (
              <>
                <div>{inputError.name}</div>
              </>
            )}
            <Form.Label className={Styles.label} controlId="">
              Icon
            </Form.Label>
            <SelectLabels
              width="fit-content"
              selectBg="transparent"
              active={inputData.icon || "select"}
              setActive={(e) => {
                setInputData((data) => {
                  return { ...data, icon: e.target.value };
                });
              }}
              slist={[
                {
                  option: (
                    <img
                      src="https://i.ibb.co/PF8vQQC/washing-Machine.png"
                      alt="it's ok"
                      className={Styles.svg}
                    />
                  ),
                },
                {
                  option: (
                    <img
                      src="https://i.ibb.co/PF8vQQC/washing-Machine.png"
                      alt="it's ok"
                      className={Styles.svg}
                    />
                  ),
                },
              ]}
            />
            {inputError.icon && (
              <>
                <div>{inputError.icon}</div>
              </>
            )}
            <Cbtn
              type="submit"
              style={{
                backgroundColor: "transparent",
                color: "#0040A8",
                float: "right",
                fontSize: "1rem",
                margin: "0px",
                padding: "0px",
              }}
            >
              Add
            </Cbtn>
          </Form.Group>
        </Form>
      )}

      <Row
        style={{
          background: "#fff",
          padding: "17px 0",
          borderRadius: "10px",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Poppins-Regular",
            color: "#363b64",
            py: 1,
            fontWeight: "600",
            paddingBottom: 0,
            fontSize: "18px",
          }}
          variant="h6"
        >
          Steps
        </Typography>
        <div style={{ position: "relative" }}>
          {!rows[0] && (
            <CircularProgress
              sx={{
                right: "10rem",
                position: "absolute",
                top: "8rem",
              }}
            />
          )}
        </div>
        <TableContainer
          sx={{ height: createJob ? "20rem" : "17rem", overflow: "auto" }}
        >
          <Table
            sx={{
              borderRadius: "50px",
              borderSpacing: "0px 15px",
              borderCollapse: "separate",
              "& .MuiTableCell-root": {
                fontFamily: "Poppins-Medium",
                color: "#363b64",
                py: 2,
              },
            }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    fontFamily: "Poppins-SemiBold",
                    border: "none",
                    color: "#363B64",
                    fontWeight: 1000,
                    fontSize: "14px",
                  },
                }}
              >
                <TableCell
                  sx={{
                    paddingTop: "0 !important",
                    paddingBottom: "0 !important",
                  }}
                >
                  Step Code
                </TableCell>
                <TableCell
                  sx={{
                    paddingTop: "0 !important",
                    paddingBottom: "0 !important",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{
                    paddingTop: "0 !important",
                    paddingBottom: "0 !important",
                  }}
                >
                  Icon
                </TableCell>
                {!createJob && (
                  <TableCell
                    sx={{
                      paddingTop: "0 !important",
                      paddingBottom: "0 !important",
                    }}
                  >
                    Delete
                  </TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {rows.map((row, index) => (
                <DraggableTRows key={index} index={index} item={row}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      m: "10px",
                      height: "10%",
                      borderLeft: "2px dashed #e7e0e0",
                      borderTop: "2px dashed #e7e0e0",
                      borderBottom: "2px dashed #e7e0e0",
                      borderRadius: "12px 0 0 12px",
                      marginTop: "26px",
                      padding: "3px 14px !important",
                    }}
                  >
                    {row.stepCode}
                  </TableCell>
                  <TableCell
                    sx={{
                      m: "10px",
                      height: "10%",
                      borderTop: "2px dashed #e7e0e0",
                      borderBottom: "2px dashed #e7e0e0",
                      padding: "3px 14px !important",
                    }}
                  >
                    <Input
                      disabled
                      value={row.name}
                      onChange={(e) => {
                        setState((state) => {
                          state.forEach((ele, i) => {
                            if (index === i) {
                              ele.name = e.target.value;
                            }
                          });
                          return [...state];
                        });
                      }}
                      disableUnderline
                      sx={{
                        ".Mui-disabled": {
                          color: "black !important",
                          "-webkit-text-fill-color": "black !important",
                        },
                        fontFamily: "inherit",
                        fontSize: "inherit",
                        borderBottom: "none",
                        maxWidth: 100,
                        outline: "none",
                      }}
                    />
                  </TableCell>

                  <TableCell
                    sx={{
                      m: "10px",
                      height: "10%",
                      borderTop: "2px dashed #e7e0e0",
                      borderBottom: "2px dashed #e7e0e0",
                      padding: "3px 14px !important",
                    }}
                  >
                    <SelectLabels
                      selectBg="transparent"
                      none={row.icon}
                      active={row.icon}
                      setActive={(e) => {
                        setState((step) => {
                          step.forEach((item, i) => {
                            if (index === i) {
                              item.activeIcon = e.target.value;
                            }
                          });
                          return [...step];
                        });
                      }}
                      slist={[
                        { option: <Washing className={Styles.svg} /> },
                        { option: <Washing className={Styles.svg} /> },
                      ]}
                    />
                  </TableCell>
                  {!createJob && (
                    <TableCell
                      sx={{
                        m: "10px",
                        height: "10%",

                        borderRight: "2px dashed #e7e0e0",
                        borderTop: "2px dashed #e7e0e0",
                        borderBottom: "2px dashed #e7e0e0",
                        borderRadius: "0 12px 12px 0",
                        padding: "3px 14px !important",
                      }}
                    >
                      <Delete
                        onClick={() => {
                          // console.log(row);
                          // dispatch(deleteSteps(row?.id));
                        }}
                        className={Styles.delete}
                      />
                    </TableCell>
                  )}
                </DraggableTRows>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!createJob && (
          <div
            style={{
              float: "right",
              textAlign: "right",
              color: "#0040A8",
              cursor: "pointer",
            }}
            onClick={() => {
              setShowMod(true);
              // setState();
            }}
          >
            <Add className={Styles.add} />
            Add Step
          </div>
        )}
      </Row>

      {/* </Container> */}
    </>
  );
}

export default Steps;
