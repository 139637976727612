import axios from "axios";
import { backendDomain } from "./paths";

const instance = axios.create({
  baseURL: backendDomain + "/api/v1/admin/",
  headers: {
    "Access-Control-Allow-Origin": "*",
    "content-type": "application/json;charset=utf-8",
    Authorization: localStorage.getItem("token"),
  },
});

export default instance;
