import React, { useEffect, useState } from "react";
import axios from "axios";
//scss
import EditBiddingPlayerStyle from "./editBiddingPlayer.module.scss";

//bootstrap
import { Form, Container, Row, Col } from "react-bootstrap";

//svg
import { ReactComponent as Save } from "../../resources/svg/save.svg";

//component
import ResponsiveDrawer from "../../components/responsiveDrawer/responsiveDrawer";
import CheckboxLabels from "../../components/checkbox/checkbox";
import Cbtn from "../../components/commonbutton/cbtn";
import SelectLabels from "../../components/dropdowns/1/dropdown1";
import Autocomplete from "../../components/autoComplete/autoComplete";
import Snackbar from "../../components/snackbar/snackbar";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import CancelIcon from "@mui/icons-material/Cancel";
import instance from "../../store/network routes/instance";
import { selectStatus } from "../../store/features/adminSlice";
import {
  getFranchise,
  getFreeFranchise,
  selectfranchiseData,
  selectFreeFranchiseData,
} from "../../store/features/franchiseSlice";
import {
  createBiddingPlayer,
  editBiddingPlayer,
  getBiddingPlayerById,
  selectBiddingPlayerDataById,
} from "../../store/features/biddingPlayerSlice";

function EditBiddingPlayer() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();

  const [editBiddingPlayerDetail, setEditBiddingPlayerDetail] = useState({
    name: "",
    nationality: "",
    role: "",
    marquee: false,
    uncapped: false,
    battingHanded: "",
    bowlingHanded: "",
    basePrice: "",
    elite: false,
    basePriceString: "",
    image: "",
  });
  const [error, setError] = React.useState("");
  const [selectedTeam, setSelectedTeam] = React.useState({});
  const [showError, setShowError] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [franchiseOptions, setFranchiseOptions] = useState([]);
  let franchises = useSelector(selectfranchiseData);

  const biddingPlayer = useSelector(selectBiddingPlayerDataById);

  const sendFile = async (file) => {
    let bodyFormData = new FormData();
    setDisableSubmit(true);
    bodyFormData.append("file", file);
    try {
      if (bodyFormData.get("file")) {
        let res = await instance({
          method: "email",
          url: "uploadFile",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        });
        if (res.data.statusCode === 200) {
          setDisableSubmit(false);
          return res.data.url;
        } else {
        }
      }
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        alert("File size should be less than 50mb");
        setDisableSubmit(false);
        document.getElementById("userIdProof").value = "";
        setEditBiddingPlayerDetail((prev) => ({
          ...prev,
          userIdProof: "",
        }));
      }
    }
  };

  useEffect(() => {
    let franchise = franchises?.find(
      (t) => t.name === editBiddingPlayerDetail?.lastBidBy?.team
    );
    let franchiseLastYear = franchises?.find(
      (t) => t.name === editBiddingPlayerDetail?.lastYear?.lastBidBy?.team
    );
    if (franchise) {
      setSelectedTeam(franchise);
    } else if (franchiseLastYear) {
      setSelectedTeam(franchiseLastYear);
    } else {
      setSelectedTeam({});
    }
  }, [
    editBiddingPlayerDetail?.lastYear?.lastBidBy?.team,
    editBiddingPlayerDetail?.lastBidBy?.team,
  ]);

  useEffect(() => {
    if (editBiddingPlayerDetail?.isSold) {
      setEditBiddingPlayerDetail((prev) => ({
        ...prev,
        soldAt: prev?.basePrice,
      }));
    } else {
      setEditBiddingPlayerDetail((prev) => ({
        ...prev,
        soldTo: null,
        soldAt: 0,
        lastBidBy: { franchise: null, team: "", owner: "", logo: "" },
      }));
    }
  }, [editBiddingPlayerDetail?.isSold]);

  useEffect(() => {
    console.log(editBiddingPlayerDetail?.lastYear?.isSold);
    if (editBiddingPlayerDetail?.lastYear?.isSold) {
      setEditBiddingPlayerDetail((prev) => ({
        ...prev,
        lastYear: { ...prev?.lastYear, soldAt: prev?.basePrice },
      }));
    } else {
      setEditBiddingPlayerDetail((prev) => ({
        ...prev,
        lastYear: {
          ...prev?.lastYear,
          soldAt: 0,
          lastBidBy: { franchise: null, team: "", owner: "", logo: "" },
        },
      }));
    }
  }, [editBiddingPlayerDetail?.lastYear?.isSold]);

  useEffect(() => {
    dispatch(getFranchise());
  }, []);

  useEffect(() => {
    if (franchises[0]) {
      setFranchiseOptions(() =>
        franchises.map((team) => ({ option: team.name }))
      );
    }
  }, [franchises]);

  React.useEffect(() => {
    if (id) {
      dispatch(getBiddingPlayerById(id));
    }
  }, [id]);

  React.useEffect(() => {
    if (biddingPlayer) {
      // console.log(biddingPlayer);
      setEditBiddingPlayerDetail({
        ...biddingPlayer,
      });
    }
  }, [biddingPlayer]);

  const handleChange = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditBiddingPlayerDetail({
        ...editBiddingPlayerDetail,
        image: res,
      });
    }
  };

  const handleChange1 = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditBiddingPlayerDetail({
        ...editBiddingPlayerDetail,
        userIdProof: res,
      });
    }
  };

  const handleNumberFormChange = (e) => {
    let value = Number(e.target.value);
    if (value >= 0)
      if (e.target.name === "basePrice") {
        if (value < 100) {
          setEditBiddingPlayerDetail({
            ...editBiddingPlayerDetail,
            basePrice: value || e.target.value,
            basePriceString: `${value || e.target.value} L`,
          });
        } else {
          setEditBiddingPlayerDetail({
            ...editBiddingPlayerDetail,
            basePrice: value || e.target.value,
            basePriceString: `${(value || e.target.value) / 100} CR`,
          });
        }
      } else {
        setEditBiddingPlayerDetail({
          ...editBiddingPlayerDetail,
          [e.target.name]: value || e.target.value,
        });
      }
  };

  const handleFormChange = (e, value) => {
    let val = value || e.target.value;
    if (e.target.name === "rating") {
      if (val > -1 && val < 100) {
        setEditBiddingPlayerDetail({
          ...editBiddingPlayerDetail,
          [e.target.name]: val,
        });
      }
    } else {
      setEditBiddingPlayerDetail({
        ...editBiddingPlayerDetail,
        [e.target.name]: val,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = false;
    const {
      name,
      nationality,
      role,
      battingHanded,
      bowlingHanded,
      basePrice,
      basePriceString,
    } = editBiddingPlayerDetail;

    validate = Object.values({
      name,
      nationality,
      role,
      battingHanded,
      bowlingHanded,
      basePrice,
      basePriceString,
    }).every(Boolean);

    if (validate) {
      if (basePrice >= 1) {
        submitForm(editBiddingPlayerDetail);
      } else {
        setError("Minimum Base Price Should Be Greater Than 1L");
        setShowError(true);
        validate = false;
      }
    } else {
      setError("Enter Full Details");
      setShowError(true);
      validate = false;
    }
  };

  const submitForm = async (data) => {
    const res = await dispatch(editBiddingPlayer(data));
    console.log(res);
    if (res.payload.statusCode === 200) {
      setEditBiddingPlayerDetail({
        name: "",
        nationality: "",
        role: "",
        marquee: false,
        uncapped: false,
        battingHanded: "",
        bowlingHanded: "",
        basePrice: "",
        elite: false,
        basePriceString: "",
        image: "",
      });
      navigate("/biddingPlayers");
    } else if (res.payload.statusCode === 400) {
      setError(res.payload.msg);
      setShowError(true);
    }
    return res;
  };

  // console.log(editBiddingPlayerDetail);

  // console.log(editBiddingPlayerDetail);
  const loadingStatus = useSelector(selectStatus);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showError}
            setShow={setShowError}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          ></Snackbar>
          <Container className={EditBiddingPlayerStyle.container}>
            <Row className={EditBiddingPlayerStyle.Container}>
              <Col>
                <h1 className={EditBiddingPlayerStyle.mainHeading}>
                  Edit Player
                </h1>
              </Col>
            </Row>
            <Row style={{ flexDirection: "row" }}>
              <Col
                as={"form"}
                xs={7}
                className={`${EditBiddingPlayerStyle.Container} ${EditBiddingPlayerStyle.formContainer}`}
                onSubmit={handleSubmit}
              >
                {/* <Row>
                  <Col>
                    <input
                      type="file"
                      className={EditBiddingPlayerStyle.customFileInput}
                      accept="image/*"
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        background: editBiddingPlayerDetail.image
                          ? `url(${editBiddingPlayerDetail.image}) #CBCBCB center center/cover`
                          : "#CBCBCB",
                        height: "110px",
                        width: "110px",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "-60px",
                        right: "70px",
                        zIndex: 2,
                      }}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Form.Group as={Col} className="mb-4" controlId="name">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Name
                    </Form.Label>
                    <Form.Control
                      className={EditBiddingPlayerStyle.control}
                      type="text"
                      name="name"
                      onChange={handleFormChange}
                      placeholder="Name"
                      value={editBiddingPlayerDetail.name}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    className="mb-4"
                    controlId="battingHanded"
                  >
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Batting
                    </Form.Label>
                    <Form.Control
                      className={EditBiddingPlayerStyle.control}
                      type="text"
                      name="battingHanded"
                      onChange={handleFormChange}
                      placeholder="Batting Handed"
                      value={editBiddingPlayerDetail.battingHanded}
                    />
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    className="mb-4"
                    controlId="bowlingHanded"
                  >
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Bowling
                    </Form.Label>
                    <Form.Control
                      className={EditBiddingPlayerStyle.control}
                      type="text"
                      name="bowlingHanded"
                      onChange={handleFormChange}
                      placeholder="Bowling Handed"
                      value={editBiddingPlayerDetail.bowlingHanded}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-4" controlId="Nationality">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Nationality
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Nationality"
                      name="nationality"
                      className={EditBiddingPlayerStyle.control}
                      value={editBiddingPlayerDetail.nationality}
                      defaultValue={0}
                      onChange={handleFormChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="Base Price">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Base Price (in L)
                    </Form.Label>
                    <Form.Control
                      type="number"
                      min={0}
                      placeholder="Base Price (in L)"
                      name="basePrice"
                      className={EditBiddingPlayerStyle.control}
                      value={editBiddingPlayerDetail.basePrice}
                      defaultValue={0}
                      onChange={handleNumberFormChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="Rating">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Rating
                    </Form.Label>
                    <Form.Control
                      className={EditBiddingPlayerStyle.control}
                      type="number"
                      name="rating"
                      onChange={handleFormChange}
                      placeholder="99"
                      min={0}
                      max={99}
                      value={editBiddingPlayerDetail.rating}
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} className="mb-4" controlId="Role">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Role
                    </Form.Label>
                    <SelectLabels
                      name="role"
                      slist={[
                        { option: "Batter" },
                        { option: "Bowler" },
                        { option: "All Rounder" },
                        { option: "Wicketkeeper" },
                      ]}
                      active={editBiddingPlayerDetail.role}
                      setActive={(e) => {
                        setEditBiddingPlayerDetail({
                          ...editBiddingPlayerDetail,
                          role: e.target.value,
                        });
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="Marquee">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Marquee
                    </Form.Label>
                    <SelectLabels
                      name="marquee"
                      slist={[{ option: "Yes" }, { option: "No" }]}
                      active={editBiddingPlayerDetail.marquee ? "Yes" : "No"}
                      setActive={(e) => {
                        setEditBiddingPlayerDetail({
                          ...editBiddingPlayerDetail,
                          marquee: e.target.value === "Yes" ? true : false,
                          elite: !(e.target.value === "Yes") && false,
                          uncapped: !(e.target.value === "Yes") && false,
                        });
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>{" "}
                  <Form.Group as={Col} className="mb-4" controlId="Elite">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Elite
                    </Form.Label>
                    <SelectLabels
                      name="elite"
                      slist={[{ option: "Yes" }, { option: "No" }]}
                      active={editBiddingPlayerDetail.elite ? "Yes" : "No"}
                      setActive={(e) => {
                        setEditBiddingPlayerDetail({
                          ...editBiddingPlayerDetail,
                          elite: e.target.value === "Yes" ? true : false,
                          marquee: !(e.target.value === "Yes") && false,
                          uncapped: !(e.target.value === "Yes") && false,
                        });
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="mb-4" controlId="uncapped">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Uncapped
                    </Form.Label>
                    <SelectLabels
                      name="uncapped"
                      slist={[{ option: "Yes" }, { option: "No" }]}
                      active={editBiddingPlayerDetail.uncapped ? "Yes" : "No"}
                      setActive={(e) => {
                        setEditBiddingPlayerDetail({
                          ...editBiddingPlayerDetail,
                          uncapped: e.target.value === "Yes" ? true : false,
                          marquee: !(e.target.value === "Yes") && false,
                          elite: !(e.target.value === "Yes") && false,
                        });
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    // xs={4}
                    // className="mb-4"
                    controlId="image"
                  >
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Image URL
                    </Form.Label>
                    <Form.Control
                      className={EditBiddingPlayerStyle.control}
                      name="image"
                      onChange={handleFormChange}
                      placeholder="Paste link here"
                      value={editBiddingPlayerDetail.image}
                    />
                  </Form.Group>
                </Row>
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: ".5rem",
                    marginBottom: "1rem",
                  }}
                >
                  <Form.Group xs={3} as={Col} controlId="isBidded">
                    <CheckboxLabels
                      value={editBiddingPlayerDetail.isBidded}
                      setValue={(e) => {
                        setEditBiddingPlayerDetail((prev) => ({
                          ...prev,
                          isBidded: !prev.isBidded,
                        }));
                      }}
                    >
                      Bidded?
                    </CheckboxLabels>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    xs={4}
                    // className="mb-4"
                    controlId="Current Bid"
                  >
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Current Bid
                    </Form.Label>
                    <Form.Control
                      disabled={!editBiddingPlayerDetail.isBidded}
                      className={EditBiddingPlayerStyle.control}
                      type="number"
                      min={"0"}
                      name="currentBid"
                      onChange={handleNumberFormChange}
                      placeholder="Current Price"
                      value={editBiddingPlayerDetail.currentBid}
                    />
                  </Form.Group>
                </Row>

                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Form.Group as={Col} xs={3} controlId="isSold">
                    <CheckboxLabels
                      value={editBiddingPlayerDetail.isSold}
                      setValue={(e) => {
                        setEditBiddingPlayerDetail((prev) => ({
                          ...prev,
                          isSold: !prev.isSold,
                        }));
                      }}
                    >
                      Sold?
                    </CheckboxLabels>
                  </Form.Group>
                  <Form.Group as={Col} xs={4} controlId="Sold At">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Sold At
                    </Form.Label>
                    <Form.Control
                      disabled={!editBiddingPlayerDetail.isSold}
                      className={EditBiddingPlayerStyle.control}
                      type="number"
                      min={0}
                      name="soldAt"
                      onChange={handleNumberFormChange}
                      placeholder="Sold At"
                      value={editBiddingPlayerDetail?.soldAt}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={4} controlId="lastBidTeam">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Sold To
                    </Form.Label>
                    <SelectLabels
                      disabled={!editBiddingPlayerDetail.isSold}
                      name="soldTo"
                      slist={franchiseOptions}
                      active={editBiddingPlayerDetail?.lastBidBy?.team}
                      setActive={(e) => {
                        if (franchises[0]) {
                          let team = franchises?.find?.(
                            (team) => team.name === e.target.value
                          );
                          setEditBiddingPlayerDetail((prev) => ({
                            ...prev,
                            soldTo: team?._id,
                            lastBidBy: {
                              team: team?.name,
                              owner: team?.owner?.name,
                              logo: team?.image,
                              franchise: team?._id,
                            },
                          }));
                        }
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Col xl={6}>
                    <Cbtn
                      disabled={disableSubmit}
                      type="submit"
                      style={{
                        fontSize: "0.8rem",
                        backgroundColor: disableSubmit ? "grey" : "#0040A8",
                        color: disableSubmit ? "black" : "#fff",
                        margin: "auto",
                        float: "right",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                        padding: "11px 34px",
                      }}
                    >
                      <Save
                        style={{ marginRight: "5px" }}
                        className={EditBiddingPlayerStyle.save}
                      />
                      Save
                    </Cbtn>
                  </Col>
                  <Col xl={6}>
                    <Cbtn
                      to="/biddingPlayers"
                      disabled={disableSubmit}
                      type="button"
                      style={{
                        backgroundColor: "rgb(57 42 42)",
                        color: "white",
                        margin: "auto",
                        fontSize: "0.8rem",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                      }}
                    >
                      Discard
                    </Cbtn>
                  </Col>
                </Row>
              </Col>
              <Col xs="4">
                <Row style={{ justifyContent: "center" }}>
                  {editBiddingPlayerDetail?.image ? (
                    <div
                      style={{
                        marginTop: "1rem",
                        // borderRadius: "50%",
                        width: "14vw",
                        height: "14vw",
                        // background: "#dee2e6",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundImage: `url(${editBiddingPlayerDetail.image})`,
                      }}
                    ></div>
                  ) : (
                    <div
                      style={{
                        marginTop: "1rem",
                        borderRadius: "50%",
                        width: "14vw",
                        height: "14vw",
                        background: "#dee2e6",
                      }}
                    ></div>
                  )}

                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "2rem",
                      marginTop: "1rem",
                      color: "#353535",
                      fontFamily: "Poppins-Semibold",
                    }}
                  >
                    {editBiddingPlayerDetail?.name || "Rohit Sharma"}
                  </span>
                  <span
                    style={{
                      fontSize: "1.3rem",
                      color: selectedTeam?.name
                        ? selectedTeam?.color?.hex || selectedTeam?.color
                        : "#d90429",
                      textAlign: "center",
                      fontFamily: "Poppins-Semibold",
                    }}
                  >
                    {selectedTeam?.name || "UNSOLD"}
                  </span>
                </Row>
                <Row
                  className={`${EditBiddingPlayerStyle.Container} ${EditBiddingPlayerStyle.formContainer}`}
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                    marginTop: "1rem",
                    width: "100%",
                    padding: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <span
                    style={{
                      marginBottom: "1rem",
                      fontSize: "1.3rem",
                      fontFamily: "Poppins-Semibold",
                    }}
                  >
                    Last Year's Record
                  </span>
                  <Form.Group as={Col} xs={2} controlId="isSoldLastYear">
                    <CheckboxLabels
                      checkboxStyle={{ margin: "0" }}
                      flexDirection="column-reverse"
                      value={editBiddingPlayerDetail?.lastYear?.isSold}
                      setValue={(e) => {
                        setEditBiddingPlayerDetail((prev) => ({
                          ...prev,
                          lastYear: {
                            ...prev?.lastYear,
                            isSold: !prev?.lastYear?.isSold,
                          },
                        }));
                      }}
                    >
                      <Form.Label
                        style={{ margin: 0 }}
                        className={EditBiddingPlayerStyle.label}
                      >
                        Sold?{" "}
                      </Form.Label>
                    </CheckboxLabels>
                  </Form.Group>
                  <Form.Group as={Col} xs={4} controlId="Sold At">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Sold At
                    </Form.Label>
                    <Form.Control
                      disabled={!editBiddingPlayerDetail?.lastYear?.isSold}
                      className={EditBiddingPlayerStyle.control}
                      type="number"
                      min={editBiddingPlayerDetail?.basePrice}
                      name="soldAt"
                      onChange={(e) => {
                        let value = Number(e.target.value);
                        if (!(value >= 0)) value = 0;
                        setEditBiddingPlayerDetail((prev) => ({
                          ...prev,
                          lastYear: {
                            ...prev?.lastYear,
                            soldAt: Math.ceil(value),
                          },
                        }));
                      }}
                      placeholder="Sold At"
                      value={editBiddingPlayerDetail?.lastYear?.soldAt}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={6} controlId="lastBidTeam">
                    <Form.Label className={EditBiddingPlayerStyle.label}>
                      Sold To
                    </Form.Label>
                    <SelectLabels
                      disabled={!editBiddingPlayerDetail?.lastYear?.isSold}
                      name="soldTo"
                      slist={franchiseOptions}
                      active={
                        editBiddingPlayerDetail?.lastYear?.lastBidBy?.team
                      }
                      setActive={(e) => {
                        if (franchises[0]) {
                          let team = franchises?.find?.(
                            (team) => team.name === e.target.value
                          );
                          setEditBiddingPlayerDetail((prev) => ({
                            ...prev,
                            lastYear: {
                              ...prev?.lastYear,
                              soldTo: team?._id,
                              lastBidBy: {
                                team: team?.name,
                                owner: team?.owner?.name,
                                logo: team?.image,
                                franchise: team?._id,
                              },
                            },
                          }));
                        }
                      }}
                      mSelect="0px"
                      selectBg="#f5f5f5"
                    />
                  </Form.Group>
                </Row>
              </Col>
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}

export default EditBiddingPlayer;
