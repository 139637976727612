import React, { useEffect, useState } from "react";
//scss
import EditTeamStyle from "./editFranchise.module.scss";

//bootstrap
import { Form, Container, Row, Col } from "react-bootstrap";

//svg & img
import { ReactComponent as Save } from "../../resources/svg/save.svg";
import Noplayer from "../../resources/images/No Player.webp";
import overseas from "../../resources/images/arshdeep.png";
import batsmen from "../../resources/images/logos/batsmen.png";
import bowler from "../../resources/images/logos/bowler.png";
import wk from "../../resources/images/logos/wicketkeeper.png";
import allRounder from "../../resources/images/logos/allrounder.png";
import uncapped from "../../resources/images/logos/uncapped.png";

//component
import ResponsiveDrawer from "../../components/responsiveDrawer/responsiveDrawer";
import Cbtn from "../../components/commonbutton/cbtn";
import SelectLabels from "../../components/dropdowns/1/dropdown1";
import Snackbar from "../../components/snackbar/snackbar";

//redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import instance from "../../store/network routes/instance";
import { selectStatus } from "../../store/features/adminSlice";
import {
  editFranchise,
  getFranchiseById,
  selectFranchiseDataById,
} from "../../store/features/franchiseSlice";
import { BlockPicker } from "react-color";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { AddPlayersToTeam } from "../../components/AddPlayersToTeam/AddPlayersToTeam";
import {
  getLastYearPlayers,
  selectGetLastYearPlayers,
} from "../../store/features/biddingPlayerSlice";

const isColor = (strColor) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== "";
};

function EditFranchise() {
  const [showAddPlayers, setShowAddPlayers] = useState(false);
  const [boughtPlayers, setBoughtPlayers] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [captainOptions, setCaptainOptions] = useState([]);
  const { id } = useParams();

  let dispatch = useDispatch();
  let navigate = useNavigate();

  let getData = async () => {
    let res = await dispatch(getLastYearPlayers(id));
    // setLoading(false);
    return res;
  };

  let TeamPlayers = useSelector(selectGetLastYearPlayers);

  // addinputkacode

  React.useEffect(() => {
    let p = TeamPlayers.map((Category) => {
      let img;
      switch (Category?._id) {
        case "Batter":
          img = batsmen;
          break;

        case "All Rounder":
          img = allRounder;
          break;

        case "Bowler":
          img = bowler;
          break;

        case "Wicketkeeper":
          img = wk;
          break;
        default:
          img = "";
      }
      return { ...Category, img };
    });

    setBoughtPlayers(p);
  }, [TeamPlayers]);

  React.useEffect(() => {
    getData();
  }, []);

  const franchise = useSelector(selectFranchiseDataById);

  const sendFile = async (file) => {
    let bodyFormData = new FormData();
    setDisableSubmit(true);
    bodyFormData.append("file", file);
    try {
      if (bodyFormData.get("file")) {
        let res = await instance({
          method: "email",
          url: "uploadFile",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        });
        if (res.data.statusCode === 200) {
          setDisableSubmit(false);
          return res.data.url;
        }
      }
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        alert("File size should be less than 50mb");
        setDisableSubmit(false);
        document.getElementById("IDProof").value = "";
        setEditTeamDetail((prev) => ({
          ...prev,
          IDProof: "",
        }));
      }
    }
  };

  const [EditTeamDetail, setEditTeamDetail] = useState({
    name: "",
    owner: "",
    color: "#ffffff",
    rtm: 0,
    nameShort: "",
    image: "",
    colorString: "",
  });

  console.log(EditTeamDetail?.colorString);

  const [error, setError] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const handleChange = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditTeamDetail({
        ...EditTeamDetail,
        formPhoto: res,
      });
    }
  };

  const handleChange1 = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditTeamDetail({
        ...EditTeamDetail,
        IDProof: res,
      });
    }
  };

  const handleFormChange = (e, value) => {
    if (e.target.name === "color") {
      if (isColor(e.target.value)) {
        setEditTeamDetail({
          ...EditTeamDetail,
          color: value || e.target.value,
          colorString: value || e.target.value,
        });
      } else {
        setEditTeamDetail({
          ...EditTeamDetail,
          colorString: value || e.target.value,
        });
      }
    } else {
      setEditTeamDetail({
        ...EditTeamDetail,
        [e.target.name]: value || e.target.value,
      });
    }
  };
  // console.log(EditTeamDetail);
  const handleSubmit = (e) => {
    e.preventDefault();
    let validate = false;
    let { name, colorString, nameShort } = EditTeamDetail;

    validate = Object.values({
      name,
      colorString,
      nameShort,
    }).every(Boolean);

    if (validate) {
      submitForm({ ...EditTeamDetail });
    } else {
      setError("Enter Full Details");
      validate = false;
      setShowError(true);
    }
  };

  React.useEffect(() => {
    if (id) dispatch(getFranchiseById(id));
  }, [id]);

  React.useEffect(() => {
    if (franchise) {
      setEditTeamDetail({ ...franchise, colorString: franchise.colorString });
      setCaptainOptions((prev) =>
        franchise?.AllPlayers?.map((player) => ({ option: player.name }))
      );
    }
  }, [franchise]);

  const submitForm = async (data) => {
    const res = await dispatch(editFranchise(data));
    if (res.payload.statusCode === 200) {
      setEditTeamDetail({
        name: "",
        owner: "",
        color: "",
        colorString: "",
        nameShort: "",
        image: "",
      });
      navigate("/teams");
    }
    return res;
  };

  const loadingStatus = useSelector(selectStatus);

  const colorPickerStyles = {
    default: {
      card: {
        boxShadow: `${EditTeamDetail?.colorString} 1px 1px 8px`,
      },
      triangle: { display: "none" },
    },
  };

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showError}
            setShow={setShowError}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          ></Snackbar>
          <Container className={EditTeamStyle.container}>
            <Row
              className={EditTeamStyle.Container}
              style={{ textTransform: "uppercase" }}
            >
              <Col>
                <h1 className={EditTeamStyle.mainHeading}>Edit Team</h1>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Row
                  as={"form"}
                  onSubmit={handleSubmit}
                  style={{ justifyContent: "center" }}
                >
                  <Row
                    style={{ justifyContent: "space-between" }}
                    className={`${EditTeamStyle.Container} ${EditTeamStyle.formContainer}`}
                  >
                    <Col md={5}>
                      <Form.Group as={Col} className="mb-3" controlId="name">
                        <Form.Label className={EditTeamStyle.label}>
                          Name
                        </Form.Label>
                        <Form.Control
                          className={EditTeamStyle.control}
                          type="name"
                          name="name"
                          onChange={handleFormChange}
                          placeholder="Name"
                          value={EditTeamDetail.name}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" controlId="email">
                        <Form.Label className={EditTeamStyle.label}>
                          Name Short (NS)
                        </Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="CSK"
                          name="nameShort"
                          className={EditTeamStyle.control}
                          value={EditTeamDetail.nameShort}
                          onChange={handleFormChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" controlId="rtm">
                        <Form.Label className={EditTeamStyle.label}>
                          RTM
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="CSK"
                          name="rtm"
                          className={EditTeamStyle.control}
                          value={EditTeamDetail?.rtm}
                          onChange={handleFormChange}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" controlId="email">
                        <Form.Label className={EditTeamStyle.label}>
                          Captain
                        </Form.Label>
                        <SelectLabels
                          name="captain"
                          // disabled={
                          //   edit?.Franchise?.name ? true : false
                          // }
                          slist={captainOptions}
                          active={(function () {
                            let captain = EditTeamDetail?.AllPlayers?.find(
                              (player) => player._id === EditTeamDetail?.captain
                            );
                            return captain?.name;
                          })()}
                          setActive={(e) => {
                            let captain = EditTeamDetail?.AllPlayers?.find(
                              (player) => player.name === e.target.value
                            );
                            if (captain) {
                              // await dispatch(
                              //   assignFranchise({
                              //     _id: editBidderDetail._id,
                              //     franchiseId: Franchise._id,
                              //   })
                              // );
                              setEditTeamDetail((prev) => ({
                                ...prev,
                                captain: captain._id,
                              }));
                            }
                          }}
                          mSelect="0px"
                          selectBg="#f5f5f5"
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-3" controlId="rtm">
                        <Form.Label className={EditTeamStyle.label}>
                          IMAGE URL
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Paste link here"
                          name="image"
                          className={EditTeamStyle.control}
                          value={EditTeamDetail?.image}
                          onChange={handleFormChange}
                        />
                      </Form.Group>
                      <Row style={{ marginTop: "2rem" }}>
                        <Col xl={6}>
                          <Cbtn
                            disabled={disableSubmit}
                            type="submit"
                            style={{
                              fontSize: "0.8rem",
                              backgroundColor: disableSubmit
                                ? "grey"
                                : "#0040A8",
                              color: disableSubmit ? "black" : "#fff",
                              margin: "auto",
                              float: "right",
                              borderRadius: "10px ",
                              width: "8rem",
                              fontFamily: "Poppins-SemiBold",
                              letterSpacing: "0px",
                              padding: "11px 34px",
                            }}
                          >
                            <Save
                              style={{ marginRight: "5px" }}
                              className={EditTeamStyle.save}
                            />
                            Save
                          </Cbtn>
                        </Col>
                        <Col xl={6}>
                          <Cbtn
                            to="/teams"
                            disabled={disableSubmit}
                            type="button"
                            style={{
                              backgroundColor: "rgb(57 42 42)",
                              color: "white",
                              margin: "auto",
                              fontSize: "0.8rem",
                              borderRadius: "10px ",
                              width: "8rem",
                              fontFamily: "Poppins-SemiBold",
                              letterSpacing: "0px",
                            }}
                          >
                            Discard
                          </Cbtn>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={7} style={{ display: "flex" }}>
                      <Form.Group
                        as={Col}
                        xs={6}
                        className="mb-4"
                        controlId="color"
                        style={{ position: "relative" }}
                      >
                        <Form.Label
                          className={EditTeamStyle.formLabel}
                          style={{
                            color: "#363b64 !important",
                            marginTop: "15px !important",
                          }}
                        >
                          Primary Color
                        </Form.Label>
                        <BlockPicker
                          color={EditTeamDetail.color}
                          className={EditTeamStyle.colorPicker}
                          styles={
                            colorPickerStyles

                            // width: 10,
                            // position: "fixed",
                            // top: "0px",
                            // right: "0px",
                            // bottom: "0px",
                            // left: "0px",
                          }
                          onChange={(color) => {
                            setEditTeamDetail((prev) => ({
                              ...prev,
                              color,
                              colorString: color?.hex,
                            }));
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        xs={6}
                        className="mb-4"
                        controlId="color"
                        style={{ position: "relative" }}
                      >
                        <Form.Label
                          className={EditTeamStyle.formLabel}
                          style={{
                            color: "#363b64 !important",
                            marginTop: "15px !important",
                          }}
                        >
                          Secondary Color
                        </Form.Label>
                        <BlockPicker
                          color={EditTeamDetail.secondaryColor}
                          className={EditTeamStyle.colorPicker}
                          styles={
                            colorPickerStyles

                            // width: 10,
                            // position: "fixed",
                            // top: "0px",
                            // right: "0px",
                            // bottom: "0px",
                            // left: "0px",
                          }
                          onChange={(secondaryColor) => {
                            setEditTeamDetail((prev) => ({
                              ...prev,
                              secondaryColor,
                              secondaryColorString: secondaryColor?.hex,
                            }));
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>
              </Col>
              <Col md="4">
                <Row style={{ justifyContent: "center" }}>
                  {EditTeamDetail.image ? (
                    <div
                      style={{
                        marginTop: "1rem",
                        // borderRadius: "50%",
                        width: "14vw",
                        height: "14vw",
                        // background: "#dee2e6",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundImage: `url(${EditTeamDetail.image})`,
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        marginTop: "1rem",
                        borderRadius: "50%",
                        width: "14vw",
                        height: "14vw",
                        background: "#dee2e6",
                      }}
                    />
                  )}

                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "3rem",
                      color: EditTeamDetail?.nameShort
                        ? EditTeamDetail?.colorString
                          ? EditTeamDetail?.colorString
                          : "#353535"
                        : "#dee2e6",
                      fontFamily: "Poppins-Semibold",
                    }}
                  >
                    {EditTeamDetail?.nameShort || "CSK"}
                  </span>
                  <span
                    style={{
                      fontSize: "1.3rem",
                      color: EditTeamDetail?.name
                        ? EditTeamDetail?.secondaryColorString
                          ? EditTeamDetail?.secondaryColorString
                          : "#353535"
                        : "#dee2e6",
                      textAlign: "center",
                      fontFamily: "Poppins-Semibold",
                    }}
                  >
                    {EditTeamDetail?.name || "Chennai Super Kings"}
                  </span>
                </Row>
              </Col>
            </Row>
            <Row className={EditTeamStyle.Container}>
              <Col style={{ display: "flex", justifyContent: "space-between" }}>
                <h1 className={EditTeamStyle.mainHeading}>
                  Last Year Bought Players
                </h1>
                <Cbtn
                  style={{ height: "3rem" }}
                  onClick={() => {
                    setShowAddPlayers((prev) => !prev);
                  }}
                >
                  <PersonAddIcon
                    style={{ height: "1rem", marginRight: "0.3rem" }}
                  />
                  Add Players
                </Cbtn>
              </Col>
            </Row>
            <AddPlayersToTeam
              show={showAddPlayers}
              setError={setError}
              showError={setShowError}
              setShow={setShowAddPlayers}
              franchiseId={id}
            />
            {boughtPlayers?.map((Category, i) => (
              <>
                <Row key={i} className={EditTeamStyle.Container}>
                  <Col>
                    <h1
                      className={EditTeamStyle.mainHeading}
                      style={{
                        textTransform: "uppercase",
                        fontSize: "1.3rem",
                        marginLeft: "1rem",
                        marginBottom: 0,
                      }}
                    >
                      - {Category?._id}
                    </h1>
                  </Col>
                </Row>
                <Row>
                  {Category?.Players.map((player, j) => (
                    <Col
                      key={j}
                      onClick={() => {
                        window.location.href = "/biddingPlayers/" + player?._id;
                      }}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: ".5rem",
                        position: "relative",
                        marginRight: ".5rem",
                        padding: "1rem",
                        width: "13rem",
                        alignItems: "center",
                        height: "13rem",
                      }}
                      md="2"
                      className={`${EditTeamStyle.Container} ${EditTeamStyle.formContainer}`}
                    >
                      <img
                        src={Noplayer}
                        style={{ maxWidth: "70%" }}
                        alt="dv"
                      />
                      <div style={{ position: "absolute", top: 20, left: 10 }}>
                        {player.uncapped && (
                          <img
                            src={uncapped}
                            alt="player"
                            style={{ height: "1.2rem" }}
                          ></img>
                        )}
                        {player.nationality?.toLowerCase() !== "india" && (
                          <img
                            src={overseas}
                            alt="player"
                            style={{ height: "1.2rem" }}
                          />
                        )}
                      </div>
                      <div style={{ position: "absolute", top: 20, right: 15 }}>
                        <img
                          src={Category?.img}
                          style={{ height: "1.7rem" }}
                        ></img>
                        {/* <img src={uncapped} style={{ height: "1.2rem" }}></img> */}
                      </div>
                      <div
                        style={{
                          fontSize: ".9rem",
                          marginTop: ".5rem",
                          fontFamily: "Poppins-Bold",
                        }}
                      >
                        {player?.name || "Dr. Evan Luthra"}
                      </div>
                    </Col>
                  ))}
                </Row>
              </>
            ))}
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}

export default EditFranchise;
