import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import AdminAuthService from "../network routes/authHelper";

export const checkLoginStatus = createAsyncThunk(
  "admin/checkLoginStatus",
  async () => {
    const response = await AdminAuthService.getCurrentUser(
      localStorage.getItem("token")
    );
    return response;
  }
);

export const loginAdmin = createAsyncThunk("admin/logInAdmin", async (data) => {
  const response = await AdminAuthService.login(data);
  return response;
});

export const signupAdmin = createAsyncThunk(
  "admin/signupAdmin",
  async (data) => {
    const response = await AdminAuthService.signup(data);
    return response;
  }
);

export const startAuction = createAsyncThunk("admin/startAuction", async () => {
  const response = await AdminAuthService.startAuction();
  return response;
});

export const resetAuction = createAsyncThunk("admin/resetAuction", async () => {
  const response = await AdminAuthService.resetAuction();
  return response;
});

export const stopAuction = createAsyncThunk("admin/stopAuction", async (id) => {
  const response = await AdminAuthService.stopAuction(id);
  return response;
});

export const getSession = createAsyncThunk("admin/getSession", async () => {
  const response = await AdminAuthService.getSession();
  return response;
});

const adminSlice = createSlice({
  name: "admin",
  initialState: {
    isLoggedIn: false,
    adminData: {},
    session: {},
    status: "idle", //loading;
    error: {},
  },
  reducers: {
    logout: (state, action) => {
      state.isLoggedIn = false;
      state.status = "error";
      state.adminData = {};
      localStorage.setItem("token", "");
    },
  },
  extraReducers: {
    [checkLoginStatus.pending]: (state, action) => {
      state.status = "Loading";
    },
    [checkLoginStatus.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        localStorage.setItem("token", action.payload.data.token);
        state.isLoggedIn = true;
        state.adminData = action.payload.data;
        state.status = "fulfilled";
      } else {
        localStorage.setItem("token", "");
        state.isLoggedIn = false;
        state.adminData = {};
        state.status = "error";
      }
    },
    [checkLoginStatus.rejected]: (state, action) => {
      state.error = action.error;
      state.status = "error";
    },
    [loginAdmin.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        // console.log(action.payload);
        localStorage.setItem("token", action.payload.data.token);
        state.status = "fulfilled";
        state.adminData = action.payload.data;
      } else {
        state.status = "error";
        localStorage.setItem("token", "");
        state.isLoggedIn = false;
        state.adminData = {};
      }
    },
    [loginAdmin.rejected]: (state, action) => {
      state.error = action.error;
      state.status = "error";
    },
    [startAuction.pending]: (state, action) => {
      state.status = "Loading";
    },
    [startAuction.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.session = action.payload.data;
        state.status = "fulfilled";
      } else {
        state.session = {};
        // state.status = "error";
      }
    },
    [startAuction.rejected]: (state, action) => {
      state.error = action.error;
      // state.status = "error";
    },
    [resetAuction.pending]: (state, action) => {
      state.status = "Loading";
    },
    [resetAuction.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.session = action.payload.data[0];
        state.status = "fulfilled";
      } else {
        state.session = {};
        // state.status = "error";
      }
    },
    [resetAuction.rejected]: (state, action) => {
      state.error = action.error;
      // state.status = "error";
    },
    [stopAuction.pending]: (state, action) => {
      // state.status = "Loading";
    },
    [stopAuction.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.session = {};
        // state.status = "fulfilled";
      } else {
        state.session = {};
        state.status = "error";
      }
    },
    [stopAuction.rejected]: (state, action) => {
      state.error = action.error;
      state.status = "error";
    },
    [getSession.pending]: (state, action) => {
      // state.status = "Loading";
    },
    [getSession.fulfilled]: (state, action) => {
      if (action.payload.statusCode === 200) {
        state.session = action.payload.data;
        // state.status = "fulfilled";
      } else if (action.payload.statusCode === 404) {
        state.session = {};
        // state.status = "fulfilled";
      } else {
        console.log(action.payload);
        state.session = {};
        // state.status = "error";
      }
    },
    [getSession.rejected]: (state, action) => {
      state.error = action.error;
      // state.status = "error";
    },
  },
});

export const { logout } = adminSlice.actions;
export const selectAdmin = (state) => state.admin.adminData;
export const selectSession = (state) => state.admin.session;
export const selectStatus = (state) => state.admin.status;
export const selectIsLoggedIn = (state) => state.admin.isLoggedIn;

export default adminSlice.reducer;
