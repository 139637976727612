import instance from "./instance";

class CuratorService {
  getCurators = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "getCurators",
    });
    return data;
  };

  getCuratorsById = async (query, token) => {
    let res = await instance({
      method: "get",
      url: `getCurators/${query}`,
    });
    return res;
  };

  searchCurator = async (data, token) => {
    let res = await instance({
      method: "get",
      url: "search/curator",
      data,
    });
    return res.data;
  };

  createCurator = async (data) => {
    let resdata = await instance({
      method: "post",
      url: "createCurator",
      data,
    });
    return resdata.data;
  };

  editCurator = async (data) => {
    // console.log(data);
    let res = await instance({
      method: "put",
      url: "editCurator/" + data._id,
      data,
    });
    return res.data;
  };

  assignFranchise = async (data, token) => {
    let res = await instance({
      method: "put",
      url: "assignFranchise/" + data.id,
      data,
    });
    return res.data;
  };

  deleteCurator = async (id, token) => {
    let res = await instance({
      method: "delete",
      url: "deleteCurator/" + id,
    });
    return res.data;
  };
}

export default new CuratorService();
