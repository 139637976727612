import instance from "./instance";

class FranchiseService {
  getFranchises = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "getFranchises",
    });
    return data;
  };

  getFreeFranchises = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "getFreeFranchises",
    });
    return data;
  };

  getFranchisesById = async (id, token) => {
    let res = await instance({
      method: "get",
      url: `getFranchise/${id}`,
    });
    return res.data;
  };

  searchFranchise = async (data, token) => {
    let res = await instance({
      method: "get",
      url: "search/franchise",
      data,
    });
    return res.data;
  };

  createFranchise = async (data) => {
    // console.log(data);
    let resdata = await instance({
      method: "post",
      url: "createFranchise",
      data,
    });
    return resdata.data;
  };

  editFranchise = async (data) => {
    let res = await instance({
      method: "put",
      url: "editFranchise/" + data._id,
      data,
    });
    return res.data;
  };

  assignOwner = async (data, token) => {
    let res = await instance({
      method: "put",
      url: "assignOwner/" + data._id,
      data,
    });
    return res.data;
  };

  removeOwner = async (data, token) => {
    let res = await instance({
      method: "put",
      url: "removeOwner/" + data._id,
      data,
    });
    return res.data;
  };

  deleteFranchise = async (id, token) => {
    let res = await instance({
      method: "delete",
      url: "deleteFranchise/" + id,
    });
    return res.data;
  };
}

export default new FranchiseService();
