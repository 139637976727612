import instance from "./instance";
class AdminAuthService {
  signup = async (data) => {
    let res = await instance({
      method: "post",
      url: "signup",
      data,
    });
    return res.data;
  };

  login = async (data) => {
    let res = await instance({
      method: "post",
      url: "login",
      data,
    });
    return res.data;
  };

  getCurrentUser = async (token) => {
    let { data } = await instance({
      method: "get",
      url: "getProfile",
    });
    return data;
  };

  startAuction = async () => {
    let { data } = await instance({
      method: "post",
      url: "startAuction",
    });
    return data;
  };

  resetAuction = async () => {
    let { data } = await instance({
      method: "put",
      url: "resetAuction",
    });
    return data;
  };

  stopAuction = async (id) => {
    let { data } = await instance({
      method: "put",
      url: "stopAuction/" + id,
    });
    return data;
  };

  getSession = async () => {
    let { data } = await instance({
      method: "get",
      url: "sessions",
    });
    return data;
  };
}

export default new AdminAuthService();
