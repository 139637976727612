import React, { useState } from "react";

// sass import
import Styles from "./deletepass.module.scss";

// importing bootstrap
import { Form } from "react-bootstrap";

// importing components
import CModal from "../modal/modal";
import Cbtn from "../commonbutton/cbtn";

function DeletePass({ show, setShow, setDelShow }) {
  const [delPass, setDelPass] = useState({
    passwordDel: "",
  });

  const deletePassword = (e) => {
    setDelPass({
      ...delPass,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <CModal
        show={true}
        onHide={() => setShow(false)}
        title=""
        subtitle="osinvoin"
        className={Styles.cmodal}
        centered
      >
        {/* ----------------------subtitle-------------------------- */}
        <div className={Styles.Subtitle} type="subtitle">
          Please enter your password to delete this item
        </div>
        {/* ----------------body--------------- */}
        <div className={Styles.Body} type="body">
          <Form
            className={Styles.form}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Form.Group className="mb-3" controlId=" passwordDel">
              <Form.Control
                type="password"
                name="passwordDel"
                className={Styles.formControl}
                onChange={deletePassword}
                value={delPass.passwordDel}
              />
            </Form.Group>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Cbtn
                style={{ backgroundColor: "#0040A8", marginRight: "1.5rem" }}
                type="submit"
              >
                Submit
              </Cbtn>
              <Cbtn
                onClick={() => {
                  setShow(false);
                  setDelShow(false);
                }}
                style={{ backgroundColor: "#363b64" }}
                type="submit"
              >
                Cancel
              </Cbtn>
            </div>
          </Form>
        </div>
      </CModal>
    </>
  );
}

export default DeletePass;
