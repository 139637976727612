import React, { useEffect, useState } from "react";
import axios from "axios";
//scss
import AddTeamStyle from "./addFranchise.module.scss";

//bootstrap
import { Form, Container, Row, Col } from "react-bootstrap";

//svg
import { ReactComponent as Save } from "../../resources/svg/save.svg";

//component
import ResponsiveDrawer from "../../components/responsiveDrawer/responsiveDrawer";
import Cbtn from "../../components/commonbutton/cbtn";
import SelectLabels from "../../components/dropdowns/1/dropdown1";
import Autocomplete from "../../components/autoComplete/autoComplete";
import Snackbar from "../../components/snackbar/snackbar";
//redux
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import CancelIcon from "@mui/icons-material/Cancel";
import instance from "../../store/network routes/instance";
import { selectStatus } from "../../store/features/adminSlice";
import {
  createFranchise,
  getFranchise,
  selectfranchiseData,
} from "../../store/features/franchiseSlice";
import {
  getFreeBidder,
  selectFreeBidderData,
} from "../../store/features/teamOwnerSlice";
import { SketchPicker } from "react-color";

const isColor = (strColor) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== "";
};

function AddFranchise() {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [ownerOptions, setOwnerOptions] = useState([]);

  let dispatch = useDispatch();
  let navigate = useNavigate();

  let owners = useSelector(selectFreeBidderData);

  // console.log(owners);

  const sendFile = async (file) => {
    let bodyFormData = new FormData();
    setDisableSubmit(true);
    bodyFormData.append("file", file);
    try {
      if (bodyFormData.get("file")) {
        let res = await instance({
          method: "email",
          url: "uploadFile",
          data: bodyFormData,
          headers: {
            "Content-Type": "multipart/form-data; ",
          },
        });
        if (res.data.statusCode === 200) {
          setDisableSubmit(false);
          return res.data.url;
        }
      }
    } catch (e) {
      if (e.code === "ERR_NETWORK") {
        alert("File size should be less than 50mb");
        setDisableSubmit(false);
        document.getElementById("IDProof").value = "";
        setEditTeamDetail((prev) => ({
          ...prev,
          IDProof: "",
        }));
      }
    }
  };

  const [teamDetail, setEditTeamDetail] = useState({
    name: "",
    owner: "",
    color: "",
    nameShort: "",
    image: "",
    colorString: "",
  });

  const [error, setError] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const handleChange = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditTeamDetail({
        ...teamDetail,
        formPhoto: res,
      });
    }
  };

  const handleChange1 = async (e) => {
    let res = await sendFile(e.target.files[0]);
    if (res) {
      setEditTeamDetail({
        ...teamDetail,
        IDProof: res,
      });
    }
  };

  const handleFormChange = (e, value) => {
    if (e.target.name === "color") {
      if (isColor(e.target.value)) {
        setEditTeamDetail({
          ...teamDetail,
          color: value || e.target.value,
          colorString: value || e.target.value,
        });
      } else {
        setEditTeamDetail({
          ...teamDetail,
          colorString: value || e.target.value,
        });
      }
    } else {
      setEditTeamDetail({
        ...teamDetail,
        [e.target.name]: value || e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    let validate = false;
    let { name, colorString, color, nameShort } = teamDetail;

    validate = Object.values({
      name,
      colorString,
      color,
      nameShort,
    }).every(Boolean);

    if (validate) {
      if (isColor(colorString)) {
        submitForm({ name, color, colorString, owner: null, nameShort });
      } else {
        setError("Select A Valid Color!");
        validate = false;
        setShowError(true);
      }
    } else {
      setError("Enter Full Details");
      validate = false;
      setShowError(true);
    }
  };

  useEffect(() => {
    dispatch(getFreeBidder());
  }, []);

  useEffect(() => {
    if (owners[0]) {
      setOwnerOptions(() => owners.map((owner) => ({ option: owner.name })));
    }
  }, [owners]);

  const submitForm = async (data) => {
    const res = await dispatch(createFranchise(data));
    if (res?.payload?.statusCode === 200) {
      setEditTeamDetail({
        name: "",
        color: "",
        nameShort: "",
        image: "",
      });
      navigate("/teams");
    } else if (res?.payload?.statusCode === 400) {
      setError(res?.payload?.msg);
      setShowError(true);
    }
    return res;
  };

  const loadingStatus = useSelector(selectStatus);
  console.log(teamDetail);
  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Snackbar
            show={showError}
            setShow={setShowError}
            errorMsg={error}
            location={{ vertical: "bottom", horizontal: "center" }}
          />
          <Container className={AddTeamStyle.container}>
            <Row className={AddTeamStyle.Container}>
              <Col>
                <h1 className={AddTeamStyle.mainHeading}>Add Team</h1>
                <span className={AddTeamStyle.subtitle}>
                  to add image, <b> SAVE</b> and <b>EDIT</b> this <b> TEAM</b>{" "}
                </span>
              </Col>
            </Row>
            <Row>
              <Col md="7">
                <Row
                  as={"form"}
                  onSubmit={handleSubmit}
                  style={{ justifyContent: "center" }}
                >
                  <Row
                    className={`${AddTeamStyle.Container} ${AddTeamStyle.formContainer}`}
                    style={{ flexDirection: "row" }}
                  >
                    <Col>
                      <Form.Group as={Col} className="mb-4" controlId="name">
                        <Form.Label className={AddTeamStyle.label}>
                          Name
                        </Form.Label>
                        <Form.Control
                          className={AddTeamStyle.control}
                          type="name"
                          name="name"
                          onChange={handleFormChange}
                          placeholder="Name"
                          value={teamDetail.name}
                        />
                      </Form.Group>
                      <Form.Group as={Col} className="mb-4" controlId="email">
                        <Form.Label className={AddTeamStyle.label}>
                          Name Short (NS)
                        </Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="CSK"
                          name="nameShort"
                          className={AddTeamStyle.control}
                          value={teamDetail.nameShort}
                          onChange={handleFormChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        as={Col}
                        className="mb-4"
                        controlId="color"
                        style={{ position: "relative" }}
                      >
                        <Form.Label
                          className={AddTeamStyle.formLabel}
                          style={{
                            color: "#363b64 !important",
                            marginTop: "15px !important",
                          }}
                        >
                          Color
                        </Form.Label>
                        <SketchPicker
                          color={teamDetail.color}
                          style={{
                            position: "fixed",
                            top: "0px",
                            right: "0px",
                            bottom: "0px",
                            left: "0px",
                          }}
                          onChange={(color) => {
                            setEditTeamDetail((prev) => ({
                              ...prev,
                              color,
                              colorString: color?.hex,
                            }));
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Col xl={6}>
                    <Cbtn
                      disabled={disableSubmit}
                      type="submit"
                      style={{
                        fontSize: "0.8rem",
                        backgroundColor: disableSubmit ? "grey" : "#0040A8",
                        color: disableSubmit ? "black" : "#fff",
                        margin: "auto",
                        float: "right",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                        padding: "11px 34px",
                      }}
                    >
                      <Save
                        style={{ marginRight: "5px" }}
                        className={AddTeamStyle.save}
                      />
                      Save
                    </Cbtn>
                  </Col>
                  <Col xl={6}>
                    <Cbtn
                      to="/teams"
                      disabled={disableSubmit}
                      type="button"
                      style={{
                        backgroundColor: "rgb(57 42 42)",
                        color: "white",
                        margin: "auto",
                        fontSize: "0.8rem",
                        borderRadius: "10px ",
                        width: "8rem",
                        fontFamily: "Poppins-SemiBold",
                        letterSpacing: "0px",
                      }}
                    >
                      Discard
                    </Cbtn>
                  </Col>
                </Row>
              </Col>{" "}
              <Col md="5">
                <Row style={{ justifyContent: "center" }}>
                  <div
                    style={{
                      marginTop: "1rem",
                      borderRadius: "50%",
                      width: "14vw",
                      height: "14vw",
                      background: "#dee2e6",
                    }}
                  ></div>
                  <span
                    style={{
                      textAlign: "center",
                      fontSize: "3rem",
                      color: teamDetail?.nameShort
                        ? teamDetail?.colorString
                          ? teamDetail?.colorString
                          : "#353535"
                        : "#dee2e6",
                      fontFamily: "Poppins-Semibold",
                    }}
                  >
                    {teamDetail?.nameShort || "CSK"}
                  </span>
                  <span
                    style={{
                      fontSize: "1.3rem",
                      color: teamDetail?.name
                        ? teamDetail?.colorString
                          ? teamDetail?.colorString
                          : "#353535"
                        : "#dee2e6",
                      textAlign: "center",
                      fontFamily: "Poppins-Semibold",
                    }}
                  >
                    {teamDetail?.name || "Chennai Super Kings"}
                  </span>
                </Row>
              </Col>
            </Row>
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}

export default AddFranchise;
