import React, { useEffect } from "react";
import {
  checkLoginStatus,
  logout,
  selectAdmin,
} from "../../store/features/adminSlice";

//scss
import AdminProfileStyle from "./adminProfile.module.scss";

//img
import dinesh from "../../resources/images/dinesh.png";

//bootstrap import
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSelect } from "@mui/base";

function AdminProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let profile = useSelector(selectAdmin);
  useEffect(() => {
    // dispatch(checkLoginStatus());
  }, []);

  return (
    <Row
      className={AdminProfileStyle.container}
      style={{
        width: "max-content",
      }}
    >
      <Col xs={6} className={AdminProfileStyle.nameNrole}>
        <div className={AdminProfileStyle.name}>
          {profile?.name ? profile?.name?.split(" ")[0] : "Admin"}
        </div>
        <div className={AdminProfileStyle.role}>
          <button
            className={AdminProfileStyle.logout}
            onClick={() => {
              dispatch(logout());
              window.location.reload();
            }}
          >
            Log out
          </button>
        </div>
      </Col>
      <Col xs={6} className={AdminProfileStyle.image}>
        <img
          className={AdminProfileStyle.adminimage}
          src={dinesh}
          alt="worker"
        />
      </Col>
    </Row>
  );
}

export default AdminProfile;
