import React from "react";
import { Link, useNavigate } from "react-router-dom";

//scss import
import Cbtnstyle from "./cbtn.module.scss";

function Cbtn({
  type,
  style,
  className,
  children,
  newTab,
  to = "#",
  link = "#",
  onClick,
  onclick,
  disabled,
  onTouchEnd,
}) {
  let navigate = useNavigate();
  return (
    <>
      {link !== "#" ? (
        <button
          onTouchStart={onTouchEnd}
          style={{
            ...style,
            textDecoration: "none",
          }}
          type={`${type ? type : "button"}`}
          disabled={disabled}
          onTouchEnd={onTouchEnd}
          onClick={
            onClick || onclick
              ? onClick
                ? () => {
                    onClick();
                    if (link !== "#")
                      if (newTab) window.open(link, "_blank");
                      else window.location.href = link;
                  }
                : () => {
                    onclick();
                    if (link !== "#")
                      if (newTab) window.open(link, "_blank");
                      else window.location.href = link;
                  }
              : () => {
                  if (link !== "#")
                    if (newTab) window.open(link, "_blank");
                    else window.location.href = link;
                }
          }
          className={`${className ? className : ""} ${Cbtnstyle.cbtnClass}`}
        >
          {children}
        </button>
      ) : (
        <button
          onTouchStart={onTouchEnd}
          style={{
            ...style,
            textAlign: "center",
            textDecoration: "none",
          }}
          disabled={disabled}
          onTouchEnd={onTouchEnd}
          onClick={
            onClick || onclick
              ? onClick
                ? () => {
                    onClick();
                    if (to !== "#") navigate(to);
                  }
                : () => {
                    onclick();
                    if (to !== "#") navigate(to);
                  }
              : () => {
                  if (to !== "#") navigate(to);
                }
          }
          type={`${type ? type : "button"}`}
          className={`${className ? className : ""} ${Cbtnstyle.cbtnClass}`}
        >
          {children}
        </button>
      )}
    </>
  );
}
export default Cbtn;
