import { Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//bootstrap import
import { Container, Row, Col } from "react-bootstrap";
import ResponsiveDrawer from "../../components/responsiveDrawer/responsiveDrawer";

//component import
import Smallbox from "../../components/smallbox/smallbox";
import RecentJobs from "../../components/recentjobs/recentJobs";
import Cbtn from "../../components/commonbutton/cbtn";

//svg import
import { ReactComponent as Jobs } from "../../resources/svg/40-bag.svg";
//img
import graph from "../../resources/images/graph.png";

//scss import
import DashboardStyle from "./dashboard.module.scss";

//table import
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector, useDispatch } from "react-redux";
import {
  checkLoginStatus,
  getSession,
  resetAuction,
  selectSession,
  selectStatus,
  startAuction,
  stopAuction,
} from "../../store/features/adminSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import {
  getBidder,
  selectbidderData,
} from "../../store/features/teamOwnerSlice";
import {
  getFranchise,
  selectfranchiseData,
} from "../../store/features/franchiseSlice";
import {
  getBiddingPlayers,
  selectbiddingPlayersData,
} from "../../store/features/biddingPlayerSlice";
import {
  getCurator,
  selectcuratorData,
} from "../../store/features/curatorSlice";

//use Selector
function createData(
  _id,
  bundleCode,
  bundleNumber,
  primaryColorCode,
  shadeCode,
  Piece,
  faultyStep,
  size,
  button
) {
  return {
    _id,
    bundleCode,
    bundleNumber,
    primaryColorCode,
    shadeCode,
    Piece,
    faultyStep,
    size,
    button,
  };
}

export default function Dashboard() {
  let location = useLocation();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [validate, setValidate] = useState(false);
  const [show, setshow] = useState(false);
  const [acRow, setAcRow] = useState([
    createData(
      1,
      "100/001/C-1/30/XL",
      "100",
      "001#FF0000",
      "C-1#464646",
      "30",
      "180",
      "XL",
      "Re-Enable"
    ),
  ]);

  let loadingStatus = useSelector(selectStatus);
  let bidder = useSelector(selectbidderData);
  let franchise = useSelector(selectfranchiseData);
  let player = useSelector(selectbiddingPlayersData);
  let curator = useSelector(selectcuratorData);
  let session = useSelector(selectSession);

  useEffect(() => {
    if (
      bidder?.length > 0 &&
      franchise?.length > 0 &&
      curator?.length > 0 &&
      player?.length > 0
    ) {
      let worstBid = bidder?.filter((b) => b.owner === null);
      let worstTeam = franchise?.filter((b) => b.owner === null);
      if (!worstBid?.length && !worstTeam?.length) {
        setValidate(true);
      } else {
        setValidate(false);
      }
    } else {
      setValidate(false);
    }
  }, [bidder, franchise, player, curator]);

  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  useEffect(() => {
    if (loadingStatus === "error") {
      navigate("/login");
    }
  }, [loadingStatus]);

  useEffect(() => {
    dispatch(getBiddingPlayers());
    dispatch(getBidder());
    dispatch(getFranchise());
    dispatch(getCurator());
    dispatch(getSession());
  }, [loadingStatus]);

  return (
    <>
      {loadingStatus === "Loading" && <LoadingScreen />}
      {loadingStatus === "fulfilled" && (
        <ResponsiveDrawer>
          <Container
            style={{
              backgroundColor: "#F5F5F5",
              padding: "2rem 3.5rem",
              height: "100%",
              marginTop: "13rem",
            }}
          >
            {validate ? (
              <Row
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {session?._id && session?.active && (
                  <>
                    <Cbtn
                      onClick={async () => {
                        await dispatch(stopAuction(session?._id));
                        window.location.reload();
                      }}
                      style={{
                        fontWeight: 900,
                        letterSpacing: "3px",
                        wordSpacing: "9px",
                        maxWidth: "30rem",
                        fontSize: "2rem",
                        margin: "1rem",
                      }}
                    >
                      STOP AUCTION
                    </Cbtn>
                    <Cbtn
                      className={DashboardStyle.resetBtn}
                      onClick={async () => {
                        if (
                          window.confirm(
                            "Are you sure? This will reset everything!"
                          )
                        ) {
                          await dispatch(resetAuction());
                          window.location.reload();
                        }
                      }}
                      style={{
                        fontWeight: 900,
                        backgroundColor: "#9d0208",
                        letterSpacing: "2px",
                        wordSpacing: "2px",
                        maxWidth: "15rem",
                        fontSize: "1.2rem",
                        margin: "1rem",
                      }}
                    >
                      RESET AUCTION
                    </Cbtn>
                  </>
                )}{" "}
                {session?._id && !session?.active && (
                  <Cbtn
                    className={DashboardStyle.resumeBtn}
                    onClick={async () => {
                      await dispatch(startAuction());
                      window.location.reload();
                    }}
                    style={{
                      backgroundColor: "#faa307",
                      fontWeight: 900,
                      letterSpacing: "3px",
                      wordSpacing: "9px",
                      maxWidth: "30rem",
                      fontSize: "2rem",
                      margin: "1rem",
                    }}
                  >
                    RESUME AUCTION
                  </Cbtn>
                )}
                {!session?._id && !session?.active && (
                  <Cbtn
                    onClick={async () => {
                      await dispatch(startAuction());
                      window.location.reload();
                    }}
                    style={{
                      fontWeight: 900,
                      letterSpacing: "3px",
                      wordSpacing: "9px",
                      maxWidth: "30rem",
                      fontSize: "2rem",
                      margin: "1rem",
                    }}
                  >
                    START AUCTION
                  </Cbtn>
                )}
              </Row>
            ) : (
              <>
                <div>1. Add Bidders</div>
                <div>2. Add Franchises</div>
                <div>3. Assign Franchises to All Bidders</div>
                <div>4. Add/Update Players</div>
              </>
            )}
          </Container>
        </ResponsiveDrawer>
      )}
    </>
  );
}
