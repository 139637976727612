import React from "react";

import Styles from "./loadingScreen.module.scss";

const LoadingScreen = ({ mt, ml }) => {
  return (
    <div
      className={Styles.container}
      style={{ marginTop: mt || "47vh", marginLeft: ml || "50vw" }}
    >
      <div className={Styles.loader}>
        <span className={Styles.spanloader} />
        <span className={Styles.spanloader} />
        <span className={Styles.spanloader} />
        <span className={Styles.spanloader} />
      </div>
    </div>
  );
};

export default LoadingScreen;
