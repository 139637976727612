import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//scss import
import style from "./login.module.scss";

//component import

//svg import
import { ReactComponent as Eye } from "../../resources/svg/eye.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Snackbar from "../../components/snackbar/snackbar";
import Cbtn from "../../components/commonbutton/cbtn";
import {
  loginAdmin,
  selectIsLoggedIn,
  selectStatus,
} from "../../store/features/adminSlice";
import LoadingScreen from "../../components/loadingSpinner/loadingScreen";
import { getBidder } from "../../store/features/teamOwnerSlice";
import { getFranchise } from "../../store/features/franchiseSlice";
import { getBiddingPlayers } from "../../store/features/biddingPlayerSlice";
import { getCurator } from "../../store/features/curatorSlice";
import { Form } from "react-bootstrap";

const Login = () => {
  const [state, setState] = useState({
    email: "",
    password: "",
    showPass: false,
  });
  const [error, setError] = useState(null);
  const [showError, setShowError] = React.useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let loadingStatus = useSelector(selectStatus);
  let isLoggedInStatus = useSelector(selectIsLoggedIn);

  React.useEffect(() => {
    if (isLoggedInStatus) {
      navigate("/");
    }
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location, isLoggedInStatus]);

  const handleShowPass = () => {
    setState((prevState) => ({ ...prevState, showPass: !prevState.showPass }));
  };

  React.useEffect(() => {
    if (loadingStatus === "fulfilled") {
      window.location.href = "/";
    }
  }, [loadingStatus]);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    if (state.email === "") {
      setError("Invalid Username");
      setShowError(true);
    } else if (state.password === "") {
      setError("Invalid Password");
      setShowError(true);
    } else {
      let resData = await dispatch(
        loginAdmin({ userQuery: state.email, password: state.password })
      ).unwrap();
      if (resData.statusCode === 400) {
        setError(resData.msg);
        setShowError(true);
      } else if (resData.statusCode === 304) {
        setError(resData.msg);
        setShowError(true);
      } else if (resData.statusCode === 200) {
        navigate("/");
      }
      // No errors.
    }
  };

  return (
    <>
      {/* {loadingStatus === "Loading" && <LoadingScreen />} */}
      {/* {loadingStatus === "error" && ( */}
      <div className={style.containers}>
        <Snackbar
          show={showError}
          setShow={setShowError}
          errorMsg={error?.toUpperCase()}
          location={{ vertical: "bottom", horizontal: "center" }}
        />
        <div className={style.left} />
        <div className={style.right}>
          <div className={style.loginFormContainer}>
            <div className={style.p}>
              <p className={style.noaccount}>
                Don't have an account? <a href="/signup">Sign up</a>
              </p>
            </div>
            <div className={style.h2}>
              <h2 className={style.loginHeading}>Let's Get Started</h2>
            </div>
            <div className={style.form}>
              <Form className={style.loginForm} onSubmit={handleSubmitClick}>
                <div className={style.inputdiv}>
                  <div className={style.inputdiv1}>
                    <label>
                      <input
                        type="name"
                        autoFocus
                        placeholder="Username"
                        id="email"
                        className={style.input1}
                        value={state.email}
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                  <div className={style.inputdiv2}>
                    <label>
                      <input
                        type={state.showPass ? "text" : "password"}
                        autoFocus
                        placeholder="Password"
                        id="password"
                        className={style.input2}
                        value={state.password}
                        onChange={handleChange}
                      />
                      <span className={style.eyeSvg}>
                        <Eye className={style.eye} onClick={handleShowPass} />
                      </span>
                    </label>
                  </div>
                </div>
                <div className={style.buttonDiv}>
                  <Cbtn
                    className={style.huehuehue}
                    type={"submit"}
                    style={{ width: "100%", backgroundColor: "#0040a8" }}
                  >
                    Sign in
                  </Cbtn>
                </div>
                {/* <div>{error && <h6 className={style.errorex}>{error}</h6>}</div> */}
              </Form>
            </div>
            <div className={style.pg}>
              <p className={style.copyrightt}>
                All rights reserved &copy; THE SPECTORS
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* )} */}
    </>
  );
};

export default Login;
